import styled from 'styled-components';
import { Dialog } from '@mui/material';
import { BLUE_GRADIENT, DARK_BLUE, DARK_GRAY, LIGHT_GRAY, WHITE } from '../../../colors';

export const CustomDialog = styled(Dialog)`
  & .MuiDialogContentText-root {
    color: ${WHITE};
    overflow-y: clip;
  }

  & > div p {
    padding: 0px 18px 0px 24px;
    font-size: 12px;
  }

  & .MuiDialog-paper {
    background-image: ${BLUE_GRADIENT};
    color: ${WHITE};
    width: 600px;
    height: 255px;
  }

  & .MuiPaper-root {
    overflow-y: clip;
  }

  & .MuiDialogContent-root {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    gap: 18px;
    overflow-y: clip;
    padding: 10px 24px 10px 24px;
    font-size: 14px;
    font-weight: 600;
  }

  & .MuiDialogActions-root {
    padding: 0px 8px 8px;
    justify-content: flex-start;
  }

  & .MuiDialogTitle-root {
    font-size: 20px;
  }

  & .MuiDialogContentText-root {
    font-size: 14px;
    font-weight: 400;
  }

  & svg {
    font-size: 18px;
  }

  & Button {
    font-size: 14px;
  }

  @media screen and (min-width: 1920px) {
    & .MuiDialog-paper {
      max-width: 800px;
      width: 800px;
      height: 270px;
    }

    & .MuiDialogContent-root {
      font-size: 20px;
    }

    & .MuiDialogTitle-root {
      font-size: 21px;
    }

    & .MuiDialogContentText-root {
      font-size: 18px;
    }

    & > div p {
      font-size: 16px;
    }

    & svg {
      font-size: 24px;
    }

    & Button {
      font-size: 16px;
    }
  }
`;

export const Option = styled.div`
  display: flex;
  flex: 1;

  & svg{
    transform: translateX(-8px);
  }
    display: flex;
    flex: 1;
    width: 120px;
    height: 90px;

    & div {
      flex: 1;
      display: flex;
      justify-content: center;
      height: 80px;
      width: 120px;
      align-items: center;
      cursor: pointer;
      border: 1px solid ${LIGHT_GRAY};
      &.selected {
        background-color: ${DARK_BLUE};
      }
      &:hover{
        background-color: ${DARK_GRAY};
        transition: background-color 0.1s ease-in-out;
      }
      & div {
        display: flex;
        justify-content: center;
        height: 80px;
        max-width: 120px;
        border: none;
      }
    }


  @media screen and (min-width: 1920px){
      width: 221px;
      height: 108px;
      & div{
        height: 110px;
        width: 220px;

        & div {
            max-width: 170px;
        }
      }
  }

`;
