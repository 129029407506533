import React from 'react';

import { Overlay, Spinner } from './pageLoading.styles';

const PageLoading = ({ isLoading }) => (isLoading ? (
  <Overlay>
    <Spinner />
  </Overlay>
) : null);

export default PageLoading;
