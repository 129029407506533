import { formataValorMonetario } from '../../../utils/moneyUtils';
import { MONEY } from '../tableFactory';

export const demonstrativoColumns = [
  {
    id: 'idCliente', label: 'Código do Cliente', minWidth: 5, align: 'center', orderBy: true,
  },
  {
    id: 'dataDemonstrativo', label: 'Data', minWidth: 135, align: 'center', orderBy: true,
  },
  {
    id: 'totalDemonstracao', label: 'Total da Demonstração', minWidth: 135, align: 'right', format: value => formataValorMonetario(value), type: MONEY, orderBy: true,
  },
  {
    id: 'valorAPagar', label: 'Valor a Pagar', minWidth: 40, align: 'right', format: value => formataValorMonetario(value), type: MONEY, orderBy: true,
  },
  {
    id: 'prestacaoContaClienteLinkNF', label: '', minWidth: 10, align: 'center', isAction: true, orderBy: true,
  },
  {
    id: 'boletoAction', label: '', minWidth: 10, align: 'center', isAction: true, orderBy: true,
  },
];
