import { formataValorMonetario } from '../../../../utils/moneyUtils';

export function lancamentosFactory(dados) {
  return {
    dadosCliente: {
      codigo: dados?.dadosCliente?.idCliente || '',
      cnpj: dados?.dadosCliente?.cnpjCpf || '',
      nome: dados?.dadosCliente?.razaoSocialCliente || '',
    },
    processos: dados?.processos?.map(processo => ({
      idProcesso: processo?.idProcesso || '',
      dataProcesso: processo?.dataLancamento || '',
      dataAbertura: processo?.dataAbertura || '',
      debitos: formataValorMonetario(processo?.valorDebito) || '',
      creditos: formataValorMonetario(processo?.valorCredito) || '',
      historico: processo?.historico || 'Não há histórico',
      totalizacaoProcesso: {
        debitos: formataValorMonetario(processo?.valorDebito) || '',
        creditos: formataValorMonetario(processo?.valorCredito) || '',
      },
    })) || [],
    lancamentosNaoVinculados: dados?.lancamentosNaoVinculados?.map(item => ({
      historico: item?.historico || '',
      valor: formataValorMonetario(item?.valor) || '',
    })) || [],
    resumo: {
      saldoAnterior: formataValorMonetario(dados?.resumo?.saldoAnterior) || '',
      totalEstornos: formataValorMonetario(dados?.resumo?.totalEstorno) || '',
      totalCreditos: formataValorMonetario(dados?.resumo?.totalCredito) || '',
      totalPagamentos: formataValorMonetario(dados?.resumo?.totalPagamento) || '',
      totalAcordos: formataValorMonetario(dados?.resumo?.totalAcordo) || '',
      totalHonorarios: formataValorMonetario(dados?.resumo?.totalHonorario) || '',
      despesasNaoReembolsaveis: formataValorMonetario(dados?.resumo?.despesaNaoReembolsavel) || '',
      despesasReembolsaveis: formataValorMonetario(dados?.resumo?.despesaReembolsavel) || '',
      saldo: formataValorMonetario(dados?.resumo?.saldoAtual) || '',
    },
  };
}
