import React from 'react';
import { ThemeProvider } from 'styled-components';
import Paths from './main/routes/Paths';
import { AuthProvider } from './main/hooks/useAuth';
import { theme } from './theme';

function App() {
  const screenWidth = window.innerWidth;
  let screen;

  if (screenWidth > 1920) {
    screen = 'sc1920';
  } else if (screenWidth <= 1920 && screenWidth > 1366) {
    screen = 'sc1366';
  } else {
    screen = 'sc1024';
  }

  return (
    <ThemeProvider theme={theme(screen)}>
      <AuthProvider>
        <Paths />
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
