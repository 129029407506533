import React from 'react';
import { Document, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { v4 as uuidv4 } from 'uuid';
import logoLogin from '../../../assets/logo-dourado.png';
import { COMPANY_LIST, DARK_BLUE, LIGHT_GRAY, PDF_SEPARATOR, WHITE } from '../../../colors';

// Estilos
const styles = StyleSheet.create({
  page: {
    backgroundColor: `${WHITE}`,
    display: 'flex',
    gap: '8px',
    padding: '16px',
  },
  division: {
    display: 'flex',
    flexDirection: 'column',
  },
  pdfTitle: {
    display: 'flex',
    flexDirection: 'row',
    color: `${WHITE}`,
    fontSize: '16px',
    fontWeight: 'bold',
    backgroundColor: `${COMPANY_LIST}`,
    padding: '10px',
    width: '100%',
  },
  title: {
    color: `${WHITE}`,
    fontSize: '16px',
    fontWeight: 'bold',
    backgroundColor: `${PDF_SEPARATOR}`,
    textAlign: 'center',
    width: '100%',
    padding: '7px 0',
    fontFamily: 'Times-Bold',
  },
  clientContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  clientContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '5px',
    paddingTop: '5px',
  },
  clientValues: {
    fontSize: '10px',
  },
  processContainer: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '10px',
    gap: '16px',
    width: '100%',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  processHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: ' 0 30px',
    width: '85%',
  },
  processValues: {
    borderTop: `1px solid ${PDF_SEPARATOR}`,
    borderBottom: `2px solid ${PDF_SEPARATOR}`,
    padding: '5px 0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  processValueContent: {
    flex: 1,
    padding: '0 5px',
    textAlign: 'start',
    fontSize: '10px',
  },
  processFooter: {
    display: 'flex',
    flexDirection: 'row',
    width: '90%',
  },
  blueText: {
    display: 'flex',
    flexDirection: 'row',
    fontWeight: 'bold',
    color: `${DARK_BLUE}`,
    paddingRight: '20px',
    fontSize: '10px',
    fontFamily: 'Times-Bold',
  },
  historyContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '14px',
  },
  historyHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '90%',
    margin: '0 auto',
  },
  historyContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    padding: '8px',
    borderBottom: `1px solid ${PDF_SEPARATOR}`,
    borderTop: `1px solid ${PDF_SEPARATOR}`,
  },
  historyContentValue: {
    flex: 1,
    fontSize: '10px',
  },
  resumoContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    width: '70%',
    alignSelf: 'center',
  },
  resumeContentSolid: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${PDF_SEPARATOR}`,
    paddingBottom: '10px',
  },
  resumeContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  field: {
    fontSize: '10px',
    fontWeight: 'bold',
    fontFamily: 'Times-Bold',
  },
  value: {
    fontSize: '10px',
  },
  footerContainer: {
    position: 'absolute',
    bottom: 10,
    width: '95%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: 8,
  },
  footerText: {
    flex: 1,
  },
});

// Componente
const PocPdf = ({ data }) => (
  <Document>
    <Page style={styles.page} size="A4">

      <View style={styles.division} fixed>
        <View style={[styles.pdfTitle, { gap: '8px' }]}>
          <View style={[{ display: 'flex', flexDirection: 'row', flex: '1', justifyContent: 'flex-end' }]}>
            <Image src={logoLogin} style={[{ height: '30px', width: '70px' }]} />
          </View>

          <Text style={[styles.title,
            { textAlign: 'left',
              borderLeft: `1px solid ${LIGHT_GRAY}`,
              backgroundColor: 'transparent',
              padding: '7px 8px',
              flex: '1',
            }]}
          >
            Lançamentos
          </Text>
        </View>
        <View style={styles.division}>
          <Text style={[styles.title,
            { textAlign: 'left',
              padding: '7px 12px',
            }]}
          >
            Dados Cliente
          </Text>
          <View style={styles.division}>
            <View style={styles.clientContainer}>
              <View style={styles.clientContent}>
                <Text style={styles.field}>Codigo:</Text>
                <Text style={styles.clientValues}>{data.dadosCliente.codigo}</Text>
              </View>
              <View style={styles.clientContent}>
                <Text style={styles.field}>CNPJ:</Text>
                <Text style={styles.clientValues}>{data.dadosCliente.cnpj}</Text>
              </View>
              <View style={styles.clientContent}>
                <Text style={styles.field}>Nome:</Text>
                <Text style={styles.clientValues}>{data.dadosCliente.nome}</Text>
              </View>
            </View>
          </View>
        </View>

      </View>
      {data.processos.map((proc, index) => (
        <View key={proc.idProcesso} style={styles.division} wrap={false}>
          <Text style={styles.title}>
            Processo
            {' '}
            {proc.idProcesso}
          </Text>
          <View style={styles.processContainer}>
            <View style={styles.processHeader}>
              <Text style={styles.field}>Data:</Text>
              <Text style={styles.field}>Abertura:</Text>
              <Text style={styles.field}>Créditos:</Text>
              <Text style={styles.field}>Débitos:</Text>
              <Text style={styles.field}>Historico:</Text>
            </View>
            <View style={styles.processValues}>
              <Text style={styles.processValueContent}>{proc.dataProcesso}</Text>
              <Text style={styles.processValueContent}>{proc.dataAbertura}</Text>
              <Text style={styles.processValueContent}>{proc.creditos}</Text>
              <Text style={styles.processValueContent}>{proc.debitos}</Text>
              <Text style={[styles.processValueContent, { flex: 1.8 }]}>
                {proc.historico}
              </Text>
            </View>
            <View style={styles.processFooter}>
              <Text style={styles.blueText}>Totalização do Processo:</Text>
              <Text style={styles.blueText}>{proc.totalizacaoProcesso.creditos}</Text>
              <Text style={styles.blueText}>{proc.totalizacaoProcesso.debitos}</Text>
            </View>
          </View>
        </View>
      ))}

      {data.lancamentosNaoVinculados && data.lancamentosNaoVinculados.length > 0 && (
        <>
          <Text style={styles.title}>Lançamentos não vinculados a um Processo</Text>
          <View style={styles.division}>
            <View style={styles.historyContainer}>
              {data.lancamentosNaoVinculados.map(lancamento => (
                <View key={uuidv4()} style={styles.historyContent}>
                  <Text style={[styles.historyContentValue, { flex: 3 }]}>
                    {lancamento.historico}
                  </Text>
                  <Text style={[styles.historyContentValue, { textAlign: 'right' }]}>
                    {lancamento.valor}
                  </Text>
                </View>
              ))}
            </View>
          </View>
        </>
      )}

      <View style={[styles.division, { gap: '8px' }]} wrap={false}>
        <Text style={styles.title}>Resumo</Text>
        <View style={styles.resumoContainer}>
          <Text style={styles.blueText}>Posição do Cliente</Text>
          <View style={styles.resumeContentSolid}>
            <Text style={styles.field}>Saldo Anterior:</Text>
            <Text style={styles.value}>{data.resumo.saldoAnterior}</Text>
          </View>
          <View style={styles.resumeContentSolid}>
            <Text style={styles.field}>Total Estornos:</Text>
            <Text style={styles.value}>{data.resumo.totalEstornos}</Text>
          </View>
          <View style={styles.resumeContent}>
            <Text style={styles.field}>Total Creditos:</Text>
            <Text style={styles.value}>{data.resumo.totalCreditos}</Text>
          </View>
          <View style={styles.resumeContent}>
            <Text style={styles.field}>Total Pagamentos:</Text>
            <Text style={styles.value}>{data.resumo.totalPagamentos}</Text>
          </View>
          <View style={styles.resumeContentSolid}>
            <Text style={styles.field}>Total Acordos:</Text>
            <Text style={styles.value}>{data.resumo.totalAcordos}</Text>
          </View>
          <View style={styles.resumeContent}>
            <Text style={styles.field}>Total Honorarios:</Text>
            <Text style={styles.value}>{data.resumo.totalHonorarios}</Text>
          </View>
          <View style={styles.resumeContent}>
            <Text style={styles.field}>Despesas Não Reembolsáveis:</Text>
            <Text style={styles.value}>{data.resumo.despesasNaoReembolsaveis}</Text>
          </View>
          <View style={styles.resumeContentSolid}>
            <Text style={styles.field}>Despesas Reembolsáveis:</Text>
            <Text style={styles.value}>{data.resumo.despesasReembolsaveis}</Text>
          </View>
          <View style={styles.resumeContent}>
            <Text style={styles.field}>Saldo:</Text>
            <Text style={styles.value}>{data.resumo.saldo}</Text>
          </View>
        </View>
      </View>
      <View style={styles.footerContainer} fixed>
        <Text style={[styles.footerText, { textAlign: 'left' }]}>
          ABE Portal do Cliente -
          {' '}
          {new Date().toLocaleString('pt-BR')}
        </Text>
        <Text
          style={styles.footerText}
          render={({ pageNumber, totalPages }) => (
            <Text style={[{ textAlign: 'right' }]}>
              Página
              {' '}
              {pageNumber}
              {' '}
              de
              {' '}
              {totalPages}
            </Text>
          )}
          fixed
        />
      </View>

    </Page>
  </Document>
);

export default PocPdf;
