import React, { useEffect, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileArrowDown, faFileExcel, faMagnifyingGlassDollar } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import {
  ButtonContainer,
  ClientInfoContainer,
  ExportButtonWrapper,
  LancamentosContainer,
  StyledPDFLink,
  TituloContainer,
} from './lancamentosHeader.styles';

import { formatCNPJ } from '../../../../../utils/utils';
import PocPdf from '../../../pdf/PocPdf';
import { setSystemAction } from '../../../../../store/system/system.store';

function LancamentosHeader({
  idCliente,
  clienteLogado,
  openModal,
  gerarExcelLancamentos,
  isDataEmpty,
  pdfData,
}) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (pdfData === null) {
      dispatch(setSystemAction('isLoading', true));
    } else {
      dispatch(setSystemAction('isLoading', false));
    }
  }, [pdfData, dispatch]);

  const handlePdfButton = useMemo(() => (
    <>
      {
          isDataEmpty ? (
            <ExportButtonWrapper
              disabled
            >
              <FontAwesomeIcon icon={faFileArrowDown} />
              Exportar PDF
            </ExportButtonWrapper>
          ) : (
            <StyledPDFLink document={pdfData ? <PocPdf data={pdfData} /> : null} fileName="Lancamentos.pdf">
              {({ blob, url, loading, error }) => {
                if (loading) { // Durante o carregamento do PDF
                  dispatch(setSystemAction('isLoading', true)); // Habilitar o carregamento
                } else {
                  dispatch(setSystemAction('isLoading', false)); // Desabilitar o carregamento
                }

                return (
                  loading ? (
                    <ExportButtonWrapper>
                      <FontAwesomeIcon icon={faFileArrowDown} />
                      {' '}
                      Exportar PDF
                    </ExportButtonWrapper>
                  ) : (
                    <ExportButtonWrapper>
                      <FontAwesomeIcon icon={faFileArrowDown} />
                      {' '}
                      Exportar PDF
                    </ExportButtonWrapper>
                  )
                );
              }}
            </StyledPDFLink>
          )
      }
    </>
  ), [isDataEmpty, pdfData, dispatch]);

  return (
    <LancamentosContainer>
      <TituloContainer>
        <h2>Lançamentos</h2>
        <span>Dados do cliente selecionado:</span>
      </TituloContainer>
      <ClientInfoContainer>
        <span>
          <strong>Código: </strong>
          {idCliente}
        </span>
        <span>
          <strong>CNPJ: </strong>
          {formatCNPJ(clienteLogado?.cnpjCpf)}
        </span>
        <span>
          <strong>Nome: </strong>
          {clienteLogado?.nomeFantasia}
        </span>
      </ClientInfoContainer>
      <ButtonContainer>
        <ExportButtonWrapper
          onClick={openModal}
          disabled={isDataEmpty}
        >
          <FontAwesomeIcon icon={faMagnifyingGlassDollar} />
          Ver Resumo
        </ExportButtonWrapper>
        <ExportButtonWrapper
          onClick={gerarExcelLancamentos}
          disabled={isDataEmpty}
        >
          <FontAwesomeIcon icon={faFileExcel} />
          Exportar Excel
        </ExportButtonWrapper>

        {handlePdfButton}

      </ButtonContainer>
    </LancamentosContainer>
  );
}

export default LancamentosHeader;
