import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getObjectFromStorage } from '../../utils/localStorageUtils';

const useClienteLogado = () => {
  const clienteStore = useSelector(state => state.system.clienteLogado);

  return useMemo(() => {
    if (clienteStore) {
      return clienteStore;
    }

    return getObjectFromStorage('clienteLogado');
  }, [clienteStore]);
};

export default useClienteLogado;
