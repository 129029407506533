import {
  buildDefaultDevedorCommand,
  buildDefaultTituloBordero,
} from '../../main/factory/novo-devedor/novoDevedor.factory';

const SET_DEVEDOR_BORDERO_COMMAND_ACTION = 'SET_DEVEDOR_BORDERO_COMMAND_ACTION';
const SET_DEVEDOR_BORDERO_ACTION = 'SET_DEVEDOR_BORDERO_ACTION';
const SET_TITULO_BORDERO_ACTION = 'SET_TITULO_BORDERO_COMMAND_ACTION';
const ADD_TITULO_BORDERO_COMMAND_ACTION = 'ADD_TITULO_BORDERO_COMMAND_ACTION';
const REMOVE_TITULO_BORDERO_COMMAND_ACTION = 'REMOVE_TITULO_BORDERO_COMMAND_ACTION';
const SET_ID_SITE_TITULOS_EXCLUSAO_ACTION = 'SET_ID_SITE_TITULOS_EXCLUSAO_ACTION';
const RESET_BORDERO_CADASTRO_ACTION = 'RESET_BORDERO_CADASTRO_ACTION';
const LIMPAR_DEVEDOR_COMMAND_ACTION = 'LIMPAR_DEVEDOR_COMMAND_ACTION';

export const setDevedorBorderoCommandAction = (name, value) => ({
  type: SET_DEVEDOR_BORDERO_COMMAND_ACTION,
  name,
  value,
});

export const limparDevedorCommandAction = () => ({
  type: LIMPAR_DEVEDOR_COMMAND_ACTION,
});

export const setDevedorBorderoAction = (name, value) => ({
  type: SET_DEVEDOR_BORDERO_ACTION,
  name,
  value,
});

export const setTituloBorderoAction = (name, value) => ({
  type: SET_TITULO_BORDERO_ACTION,
  name,
  value,
});

export const addTituloBorderoCommandInListAction = value => ({
  type: ADD_TITULO_BORDERO_COMMAND_ACTION,
  value,
});

export const setIdSiteTitulosExclusaoAction = idSiteTitulo => ({
  type: SET_ID_SITE_TITULOS_EXCLUSAO_ACTION,
  idSiteTitulo,
});

export const removeTituloBorderoCommandAction = index => ({
  type: REMOVE_TITULO_BORDERO_COMMAND_ACTION,
  index,
});

export const resetBorderoCadastroAction = () => ({
  type: RESET_BORDERO_CADASTRO_ACTION,
});

function setDevedorBorderoCommandHandler(state, action) {
  const { name, value } = action;
  return {
    ...state,
    devedorBorderoCommand: {
      ...state.devedorBorderoCommand,
      [name]: value,
    },
  };
}

function setDevedorBorderoHandler(state, action) {
  const { name, value } = action;
  return {
    ...state,
    [name]: value,
  };
}

function setTituloBorderoHandler(state, action) {
  const { name, value } = action;
  return {
    ...state,
    tituloBordero: {
      ...state.tituloBordero,
      [name]: value,
    },
  };
}

function addTituloBorderoCommandInListHandler(state, action) {
  const { value } = action;
  const newTitulos = [...state.titulosBorderoCommand];
  newTitulos.push(value);

  return {
    ...state,
    titulosBorderoCommand: newTitulos,
  };
}

function removeTituloBorderoCommandHandler(state, action) {
  return {
    ...state,
    tituloBorderoCommand: state.tituloBorderoCommand.filter(
      (_, index) => index !== action.index,
    ),
  };
}

function setIdSiteTitulosExclusaoHandler(state, action) {
  const newIdSiteTitulosExclusao = [...state.idSiteTitulosExclusao];
  newIdSiteTitulosExclusao.push(action.idSiteTitulo);
  return {
    ...state,
    idSiteTitulosExclusao: newIdSiteTitulosExclusao,
  };
}

function limparDevedorCommandHandler(state) {
  const oldInstrucoes = state.devedorBorderoCommand.instrucoes;
  const newDevedorBorderoCommand = buildDefaultDevedorCommand();
  newDevedorBorderoCommand.instrucoes = oldInstrucoes;
  return {
    ...state,
    devedorBorderoCommand: newDevedorBorderoCommand,
  };
}

function buildDefaultStates() {
  return {
    devedorBorderoCommand: buildDefaultDevedorCommand(),
    titulosBorderoCommand: [],
    tituloBordero: buildDefaultTituloBordero(),
    idSiteTitulosExclusao: [],
    idProcesso: null,
    idSiteDevedorEdicao: null,
  };
}

function resetBorderoCadastroHandler() {
  return buildDefaultStates();
}

const initialStates = () => buildDefaultStates();

const salvarEditarDevedorReducer = (state = initialStates(), action) => {
  switch (action.type) {
    case SET_DEVEDOR_BORDERO_COMMAND_ACTION:
      return setDevedorBorderoCommandHandler(state, action);

    case SET_TITULO_BORDERO_ACTION:
      return setTituloBorderoHandler(state, action);

    case REMOVE_TITULO_BORDERO_COMMAND_ACTION:
      return removeTituloBorderoCommandHandler(state, action);

    case SET_ID_SITE_TITULOS_EXCLUSAO_ACTION:
      return setIdSiteTitulosExclusaoHandler(state, action);

    case SET_DEVEDOR_BORDERO_ACTION:
      return setDevedorBorderoHandler(state, action);

    case RESET_BORDERO_CADASTRO_ACTION:
      return resetBorderoCadastroHandler();

    case LIMPAR_DEVEDOR_COMMAND_ACTION:
      return limparDevedorCommandHandler(state);

    case ADD_TITULO_BORDERO_COMMAND_ACTION:
      return addTituloBorderoCommandInListHandler(state, action);
    default:
      return state;
  }
};

export default salvarEditarDevedorReducer;
