import { processosColumns } from '../../main/factory/pesquisa-processo/pesquisaProcesso.factory';
import getSortColumn from '../../utils/utils';

const SET_STATE_PESQUISA_PROCESSO_ACTION = 'SET_STATE_PESQUISA_PROCESSO_ACTION';
const SET_PAGEABLE_OPTIONS_VALUE_ACTION = 'SET_PAGEABLE_OPTIONS_VALUE_ACTION';
const DEFAULT_STATES_ACTION = 'DEFAULT_STATES_ACTION';
const SET_ORDENACAO_ACTION = 'SET_ORDENACAO_ACTION';
const SET_SELECTED_OPTION_ACTION = 'SET_SELECTED_OPTION_ACTION';
const CLEAR_STATE_PESQUISA_PROCESSO_ACTION = 'CLEAR_STATE_PESQUISA_PROCESSO_ACTION';

export const setOrdenacaoAction = (columnName, direction) => ({
  type: SET_ORDENACAO_ACTION,
  columnName,
  direction,
});

export const setStatePesquisaProcessoAction = (name, value) => ({
  type: SET_STATE_PESQUISA_PROCESSO_ACTION,
  name,
  value,
});

export const setPageableOptionsValueAction = (name, value) => ({
  type: SET_PAGEABLE_OPTIONS_VALUE_ACTION,
  name,
  value,
});

export const setSelectedOptionAction = payload => ({
  type: SET_SELECTED_OPTION_ACTION,
  payload,
});

export const resetPesquisaProcessoStatesAction = () => ({
  type: DEFAULT_STATES_ACTION,
});

export const clearStatePesquisaProcessoAction = () => ({
  type: CLEAR_STATE_PESQUISA_PROCESSO_ACTION,
});

function setStatePesquisaProcessoHandler(state, action) {
  return {
    ...state,
    [action.name]: action.value,
  };
}

function setPageableOptionsValueHandler(state, action) {
  return {
    ...state,
    pageableOptions: {
      ...state.pageableOptions,
      [action.name]: action.value,
    },
  };
}

function setOrdenacaoHandler(state, action) {
  return {
    ...state,
    ordenacao: state.ordenacao.map(item => (item.columnName === action.columnName
      ? { ...item, direction: action.direction }
      : item)),
  };
}

function setSelectedOptionHandler(state, action) {
  return {
    ...state,
    selectedOption: {
      ...state.selectedOption,
      ...action.payload,
    },
  };
}

function buildDefaultOrdenacao() {
  return processosColumns
    .filter(column => column.orderBy)
    .map(column => ({
      columnName: column.id,
      direction: 'ASC',
    }));
}

function buildDefaultStates() {
  return {
    content: [],
    page: 0,
    rowsPerPage: 10,
    ordenacao: buildDefaultOrdenacao(),
    orderBy: getSortColumn('idProcesso', 'ASC'),
    pageableOptions: {
      count: 0,
      last: true,
    },
    selectedOption: {
      option: '',
      placeholder: '',
    },
    searchValue: '',
    idMotivoBaixa: '',
    dataAbertura: '',
  };
}

function defaultStatesHandler() {
  return buildDefaultStates();
}

function clearStatePesquisaProcessoHandler(states) {
  return {
    ...states,
    selectedOption: {
      option: '',
      placeholder: '',
    },
    searchValue: '',
    idMotivoBaixa: '',
    dataAbertura: '',
  };
}

const initialStates = () => buildDefaultStates();

const pesquisaProcessoReducer = (state = initialStates(), action) => {
  switch (action.type) {
    case SET_STATE_PESQUISA_PROCESSO_ACTION:
      return setStatePesquisaProcessoHandler(state, action);
    case SET_PAGEABLE_OPTIONS_VALUE_ACTION:
      return setPageableOptionsValueHandler(state, action);
    case SET_ORDENACAO_ACTION:
      return setOrdenacaoHandler(state, action);
    case DEFAULT_STATES_ACTION:
      return defaultStatesHandler();
    case SET_SELECTED_OPTION_ACTION:
      return setSelectedOptionHandler(state, action);
    case CLEAR_STATE_PESQUISA_PROCESSO_ACTION:
      return clearStatePesquisaProcessoHandler(state, action);
    default:
      return state;
  }
};

export default pesquisaProcessoReducer;
