import styled from 'styled-components';
import { WHITE, COMPANY_LIST,
} from '../../../../colors';


export const MenuContainer = styled.div`
  /* background-color: #252525; */
  background-image: linear-gradient(125deg, #1C2F5A 2.34%, #23232F 35.42%);
  min-height: 60px;
  position: sticky;
  box-sizing: border-box;
  border-bottom: 1px solid grey;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;

  @media (max-width: 1280px) {
    justify-content: flex-end;
  }
`;

export const ListContainer = styled.ul`
  margin: 0;
  list-style: none;
  display: flex;
  flex: 1;
  justify-content: center;
  height: 60px;

  @media (max-width: 1050px) {
    display: none;
  }
`;

export const ListItem = styled.li`
  margin-right: 20px;
  font-weight: bold;
  font-size: 16px;
  color: #fff;
  height: 100%;
  padding: 0 20px;
  position: relative;
  list-style: none;
  user-select: none;
  cursor: pointer;
  transition: background-color 0.5s ease-in-out;

  &:hover {
    background-color: #323232;
  }
`;

export const LinkItem = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ $isSelected }) => ($isSelected ? 'white' : 'grey')};
  text-decoration: none;
  position: relative;
  height: 100%;
  gap: 10px;

  @media (min-width: 1050px) {
    &::after {
      content: "";
      display: ${({ $isSelected }) => ($isSelected ? 'block' : 'none')};
      width: 10px;
      height: 10px;
      background-color: #fff;
      border-radius: 50%;
      position: absolute;
      bottom: -5px;
    }
  }
`;

export const HamburgerButton = styled.div`
  user-select: none;
  display: none;
  font-size: 30px;
  cursor: pointer;
  color: #fff;
  justify-content: flex-end;

  @media (max-width: 1050px) {
    display: block;
  }
`;

export const CloseButton = styled.div`
  font-size: 30px;
  cursor: pointer;
  color: #fff;
  user-select: none; /* Evita a seleção do texto */
`;

export const MobileMenu = styled.div`
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  background-color: #252525;
  z-index: 1000;
  border-bottom: 1px solid gray;

  ${ListItem} {
    padding: 10px 20px;
  }
`;

export const LogoContainer = styled.div`
  position: absolute;
  left: 20px;
  cursor: pointer;

  & > img {
    height: 40px;
  }
`;

export const LogoutContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 70px;
  cursor: pointer;
  font-size: 16px;
  color: ${WHITE};
  font-weight: bold;
  gap: 10px;
`;
