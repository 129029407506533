import dayjs from 'dayjs';
import getSortColumn from '../../utils/utils';

const SET_FIELD_ACTION = 'SET_FIELD_ACTION';
const RESET_STATE_ACTION = 'RESET_STATE_ACTION';
const SET_TOTAL_INFO_ACTION = 'SET_TOTAL_INFO_ACTION';

export const setFieldAction = (name, value) => ({
  type: SET_FIELD_ACTION,
  name,
  value,
});

export const setPrevisaoDeRecebimentoTotalAction = value => ({
  type: SET_TOTAL_INFO_ACTION,
  value,
});

export const resetPrevisaoRecebimentoAction = () => ({
  type: RESET_STATE_ACTION,
});

function buildDefaultOrdenacao() {
  return [{ columnName: 'dataVencimento', direction: 'ASC' }];
}

function buildDefaultStates() {
  return {
    tableData: [],
    totalInfo: {
      totalParcelasNaoLiquidadas: 0,
      totalParcelasAVencer: 0,
      totalElements: 0,
      totalPages: 0,
    },
    page: 0,
    rowsPerPage: 10,
    ordenacao: buildDefaultOrdenacao(),
    orderBy: getSortColumn('dataVencimento', 'ASC'),
    dataInicio: dayjs().subtract(1, 'year').startOf('day'),
    dataFim: dayjs().add(1, 'month').endOf('day'),
    dataText: `(*) Previsão de parcelas a vencer até ${dayjs().add(1, 'month').endOf('day').format('DD/MM/YYYY')}`,
    currentColumn: 'dataVencimento',
  };
}

function setTotalInfoHandler(state, action) {
  return {
    ...state,
    totalInfo: action.value,
  };
}

function setFieldHandler(state, action) {
  const { name, value } = action;
  if (name === 'dataFim') {
    return {
      ...state,
      [name]: value,
      dataText: `(*) Previsão de parcelas a vencer até ${value.format('DD/MM/YYYY')}`,
    };
  }
  return {
    ...state,
    [name]: value,
  };
}

function defaultStatesHandler() {
  return buildDefaultStates();
}


const previsaoRecebimentoReducer = (state = buildDefaultStates(), action) => {
  switch (action.type) {
    case SET_TOTAL_INFO_ACTION:
      return setTotalInfoHandler(state, action);
    case SET_FIELD_ACTION:
      return setFieldHandler(state, action);
    case RESET_STATE_ACTION:
      return defaultStatesHandler();
    default:
      return state;
  }
};

export default previsaoRecebimentoReducer;
