import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ArrowBackIos } from '@mui/icons-material';
import SystemWrapper from '../../../../components/UI/SystemWrapper/SystemWrapper';
import LancamentosHeader from './LancamentosHeader';
import useClienteLogado from '../../../../hooks/useClienteLogado';
import useAuthAxios from '../../../../hooks/useAuthAxios';
import { getUriParams } from '../../../../../utils/uriParams';
import { downloadFile } from '../../../../../utils/utils';
import { setSystemAction, setToastAction } from '../../../../../store/system/system.store';

import Tabela from '../../../../components/UI/Table/Table';
import { buildHistoricoRows, lancamentosColumns } from '../../../../factory/financeiro/financeiro.factory';
import { BackButtonWrapper, Container, ContainerTabela, ContentWrapper } from './lancamentosPage.styles';
import VerResumo from './VerResumo';
import { lancamentosFactory } from '../../../perfil-cliente/dados-financeiros/pdfFactory';

function LancamentosPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authAxios = useAuthAxios();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const clienteLogado = useClienteLogado();
  const location = useLocation();

  const [paramsToExcel, setParamsToExcel] = useState(null);

  const { rowData } = location.state || {};
  const { idCliente, numPrestacao } = location.state.rowData;

  const [lancamentos, setLancamentos] = useState([]);
  const [pageableOptions, setPageableOptions] = useState({ page: 0, count: 0 });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [details, setDetails] = useState([]);
  const [pdfData, setPdfData] = useState(null);

  const isDataEmpty = lancamentos.length === 0;


  const goBack = () => {
    navigate('/prestacao-contas');
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const gerarExcelLancamentos = async () => {
    dispatch(setSystemAction('isLoading', true));
    try {
      const response = await authAxios.post(
        `/financeiro/gerar-excel-lancamentos?${getUriParams(paramsToExcel)}`,
      );
      downloadFile(response);
    } catch (error) {
      dispatch(
        setToastAction(
          true,
          'error',
          'Houve um erro ao realizar o download, tente novamente',
        ),
      );
    } finally {
      dispatch(setSystemAction('isLoading', false));
    }
  };

  // const handleKeyPress = (event) => {
  //   if (event.key === 'Enter' || event.key === ' ') {
  //     gerarExcelLancamentos();
  //   }
  // };

  const buildPdfUrl = (params) => {
    const urlParams = new URLSearchParams(params);
    return `/gerarPdf/dados-lancamentos?${urlParams.toString()}`;
  };

  const fetchLancamentos = useCallback(
    async (currentPage = 0, size = rowsPerPage) => {
      dispatch(setSystemAction('isLoading', true));

      try {
        const lancamentoCommand = {
          idCliente,
          numPrestacao,
        };

        const params = getUriParams({
          page: currentPage,
          size,
          ...lancamentoCommand,
        });

        const response = await authAxios.get(
          `/financeiro/lancamentos?${params}`,
        );

        const data = response.data.content.map(item => ({
          dataLancamento: item.dataLancamento,
          idProcesso: item.idProcesso,
          dataAbertura: item.dataAbertura,
          valorCredito: item.valorCredito,
          valorDebito: item.valorDebito,
          historico: item.historico,
        }));

        if (!pdfData) {
          const responsePDF = await authAxios.get(buildPdfUrl(lancamentoCommand));
          const formattedPdfData = lancamentosFactory(responsePDF.data);
          setPdfData(formattedPdfData);
        }


        setLancamentos(data);

        const detailsData = buildHistoricoRows(data);
        setDetails(detailsData);

        setPageableOptions({
          page: response.data.page,
          count: response.data.totalElements,
        });
      } catch (error) {
        dispatch(setToastAction(true, 'error', error.message));
      } finally {
        dispatch(setSystemAction('isLoading', false));
      }
    },
    [authAxios, dispatch, idCliente, location.state.rowData.numPrestacao, rowsPerPage, pdfData],
  );


  useEffect(() => {
    if (idCliente) {
      setParamsToExcel({
        idCliente,
        numPrestacao,
      });
    }
  }, [idCliente, numPrestacao]);

  useLayoutEffect(() => {
    if (idCliente && rowData) {
      fetchLancamentos(0, 10).then();
    }
  }, [fetchLancamentos, idCliente, rowData]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    fetchLancamentos(newPage, rowsPerPage).then();
  };

  const handleRowsPerPageChange = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setRowsPerPage(newSize);
    setPage(0);
  };

  return (
    <SystemWrapper>
      <Container>
        <BackButtonWrapper onClick={goBack}>
          <ArrowBackIos />
          Voltar
        </BackButtonWrapper>
      </Container>
      <LancamentosHeader
        idCliente={idCliente}
        clienteLogado={clienteLogado}
        openModal={openModal}
        gerarExcelLancamentos={gerarExcelLancamentos}
        isDataEmpty={isDataEmpty}
        pdfData={pdfData}
      />
      <ContainerTabela>
        <ContentWrapper>
          <Tabela
            columns={lancamentosColumns}
            rows={lancamentos}
            page={page}
            count={pageableOptions.count}
            rowsPerPage={rowsPerPage}
            changePage={handlePageChange}
            changeRowsPerPage={handleRowsPerPageChange}
            details={details}
          />
        </ContentWrapper>
      </ContainerTabela>
      {isModalOpen && (
        <VerResumo
          handleClose={closeModal}
          idCliente={idCliente}
          numPrestacao={numPrestacao}
        />
      )}
    </SystemWrapper>
  );
}

export default LancamentosPage;
