import styled from 'styled-components';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { Button } from '@mui/material';
import { DARK_BLUE, DARK_GRAY, DISABLED, LIGHT_GRAY, WHITE } from '../../../../../colors';

export const LancamentosContainer = styled.div`
   display: flex;
  flex-direction: column;
  color: #fff;

  & * {
    margin: 0;
  }
`;

export const TituloContainer = styled.div`
  color: ${WHITE};
  text-align: start;
  width: 100%;
  padding: 14px 10px;
  font-size: 16px;
  padding-top: initial;

  & h3 {
    color: ${WHITE};
    text-align: start;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 6px;
  }

  & span {
    color: ${WHITE};
    font-size: 16px;
    font-weight: 500;
  }
`;

export const ClientInfoContainer = styled.div`
  color: ${WHITE};
  padding: 14px 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-style: solid;
  border-width: 0.5px;
  border-color: ${LIGHT_GRAY};

  & span {
    font-size: 16px;
    margin-bottom: 5px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 26px 0px 26px 0px;
  width: 100%;
  gap: 28px;
`;

export const CustomButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 151px;
  height: 79px;
  cursor: pointer;
  background-color: ${({ bgcolor }) => bgcolor || '#046EB3'};
  color: ${WHITE};
  border: none;
  padding: 16px;
  font-weight: 700;
  font-size: 16px;
  gap: 8px;

  & h3 {
    margin-bottom: 0px;
    margin-top: 6px;
    font-size: 16px;
  }

  & span {
    display: inline;
    align-items: center;
    gap: 8px;
  }
  &:disabled {
    background-color: ${DISABLED};
  }
`;

export const ExportButtonWrapper = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  &.MuiButton-root{
    background-color: ${DARK_BLUE};
    color: white;
    border-radius: 0px;
    padding: 8px 16px;
    text-transform: none;
    font-weight: 500;
    font-size: 12px;
  }

  &.MuiButton-root.Mui-disabled{
    color: ${DISABLED};
    background-color: transparent;
    border: 1px solid ${DISABLED};
  }

  & .svg-inline--fa {
    font-size: 18px;
  }

  &:hover:enabled {
    background-color: ${DARK_GRAY};
    transition: background-color 0.1s ease-in-out;
  }

  @media screen and (min-width: 1366px) {
    &.MuiButton-root{
      font-size: 14px;
    }
  }

  @media screen and (min-width: 1920px) {
    &.MuiButton-root{
      font-size: 16px;
    }
  }
`;

export const StyledPDFLink = styled(PDFDownloadLink)`
  text-decoration: none;
  color: inherit;
`;
