import { formataValorMonetario } from '../../../utils/moneyUtils';

export const lancamentosColumns = [
  { id: 'dataLancamento', label: 'Data', align: 'center', minWidth: 80 },
  { id: 'idProcesso', label: 'Processo', align: 'center', minWidth: 80 },
  { id: 'dataAbertura', label: 'Abertura Processo', align: 'center', minWidth: 0 },
  { id: 'valorCredito', label: 'Créditos', align: 'right', format: value => formataValorMonetario(value), minWidth: 80 },
  { id: 'valorDebito', label: 'Débitos', align: 'right', format: value => formataValorMonetario(value), minWidth: 100 },
  { id: 'historico', label: 'Histórico', align: 'center', minWidth: 80 },
  { id: 'expandirAction', label: '', align: 'center', minWidth: 20, isExpansivel: true, isAction: true },
];


export function buildHistoricoRows(lancamentos) {
  return lancamentos.map(lancamento => (
    <div style={{ padding: '16px' }} key={lancamento.idProcesso}>
      <h3>Histórico</h3>
      <p>{lancamento.historico}</p>
    </div>
  ));
}
