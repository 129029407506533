import React from 'react';
import GridViewIcon from '@mui/icons-material/GridView';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';

export const menuOptions = [
  {
    id: 'dashboard',
    label: 'Início',
    // icon: <GridViewIcon />,
  },
  {
    id: 'financeiro',
    label: 'Financeiro',
    // icon: <AttachMoneyIcon />,
  },
  {
    id: 'relatorios',
    label: 'Relatório',
    // icon: <FeedOutlinedIcon />,
  },
  {
    id: 'fale-conosco',
    label: 'Fale Conosco',
    // icon: <ChatOutlinedIcon />,
  },
  {
    id: 'perfil',
    label: 'Perfil',
    // icon: <PersonOutlineOutlinedIcon />,
  },
];
