export function setEmpresasSelecionadasStorage(empresasSelecionadas) {
  const selecionadas = localStorage.getItem('idsEmpresasSelecionadas');
  if (selecionadas) {
    localStorage.removeItem('idsEmpresasSelecionadas');
  }
  localStorage.setItem('idsEmpresasSelecionadas', JSON.stringify(empresasSelecionadas));
}

export function getEmpresasSelecionadasStorage() {
  return JSON.parse(localStorage.getItem('idsEmpresasSelecionadas'));
}

export function getObjectFromStorage(key) {
  return JSON.parse(localStorage.getItem(key));
}
