import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  ResumoModalContainer,
  ResumoModalContent,
  ResumoModalHeader,
  ResumoModalBody,
  ResumoModalFooter,
  CloseButton,
  ResumoValue,
  ResumoSaldo,
} from './verResumo.styles';
import useAuthAxios from '../../../../hooks/useAuthAxios';
import { setSystemAction, setToastAction } from '../../../../../store/system/system.store';
import { formataValorMonetario } from '../../../../../utils/moneyUtils';

function VerResumo({ handleClose, idCliente, numPrestacao }) {
  const dispatch = useDispatch();
  const authAxios = useAuthAxios();
  const [resumoData, setResumoData] = useState(null);
  const ResumoItens = ({ label, value }) => (
    <ResumoValue>
      <ResumoSaldo>{label}</ResumoSaldo>
      <ResumoSaldo>{value}</ResumoSaldo>
    </ResumoValue>
  );

  useEffect(() => {
    const fetchResumoData = async () => {
      if (idCliente && numPrestacao) {
        dispatch(setSystemAction('isLoading', true));
        try {
          const response = await authAxios.get('/financeiro/resumo-prestacao-contas', {
            params: { idCliente, numPrestacao },
          });
          setResumoData(response.data);
        } catch (error) {
          dispatch(setToastAction(true, 'error', error.message));
        } finally {
          dispatch(setSystemAction('isLoading', false));
        }
      }
    };
    fetchResumoData();
  }, [idCliente, numPrestacao, authAxios, dispatch]);


  const handleModalClose = () => {
    if (handleClose) {
      handleClose();
    }
  };

  return (
    <ResumoModalContainer>
      <ResumoModalContent>
        <ResumoModalHeader>
          <h2>Posição do Cliente</h2>
        </ResumoModalHeader>
        <ResumoModalBody>
          <ResumoItens
            label="Saldo Anterior"
            value={formataValorMonetario(resumoData?.saldoAnterior)}
          />
          <ResumoItens
            label="Total de Estornos"
            value={formataValorMonetario(resumoData?.totalEstornos)}
          />
          <ResumoItens
            label="Total de Créditos"
            value={formataValorMonetario(resumoData?.totalCreditos)}
          />
          <hr />
          <ResumoItens
            label="Total Pagamentos"
            value={formataValorMonetario(resumoData?.totalPagamentos)}
          />
          <ResumoItens
            label="Total Acordos"
            value={formataValorMonetario(resumoData?.totalAcordos)}
          />
          <hr />
          <ResumoItens
            label="Total Honorários"
            value={formataValorMonetario(resumoData?.totalHonorarios)}
          />
          <ResumoItens
            label="Despesa Não Reembolsável"
            value={formataValorMonetario(resumoData?.despesaNaoReembolsavel)}
          />
          <ResumoItens
            label="Despesa Reembolsável"
            value={formataValorMonetario(resumoData?.despesaReembolsavel)}
          />
          <hr />
          <ResumoItens
            label="Saldo"
            value={formataValorMonetario(resumoData?.saldoAtual)}
            isSaldo
          />
        </ResumoModalBody>
        <ResumoModalFooter>
          <CloseButton onClick={handleModalClose}>OK</CloseButton>
        </ResumoModalFooter>
      </ResumoModalContent>
    </ResumoModalContainer>
  );
}

export default VerResumo;
