import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow'; import { formataValorMonetario } from '../../../utils/moneyUtils';
import { TableRoot } from '../../components/UI/Table/table.styles';
import { MONEY } from '../tableFactory';

export const processosColumns = [
  {
    id: 'idCliente', label: 'Código do Cliente', minWidth: 5, orderBy: true, align: 'center',
  },
  {
    id: 'nomeFantasiaCliente', label: 'Nome do Cliente', minWidth: 135, align: 'left', orderBy: true, showHint: true,
  },
  {
    id: 'dataAbertura', label: 'Data', minWidth: 70, align: 'center', orderBy: true,
  },
  {
    id: 'idProcesso', label: 'Processo', minWidth: 10, align: 'center', orderBy: true,
  },
  {
    id: 'nomeFantasiaDevedor', label: 'Devedor', minWidth: 80, align: 'left', orderBy: true, showHint: true,
  },
  {
    id: 'valorPrincipal', label: 'Valor Principal', minWidth: 40, align: 'right', format: value => formataValorMonetario(value), orderBy: true, type: MONEY,
  },
  {
    id: 'valorSaldoDevedor', label: 'Saldo Devedor', minWidth: 40, align: 'right', format: value => formataValorMonetario(value), orderBy: true, type: MONEY,
  },
  {
    id: 'descricaoMotivoBaixa', label: 'Status', minWidth: 60, align: 'left', orderBy: true, showHint: true,
  },
  {
    id: 'ufCidade', label: 'UF / Cidade', minWidth: 120, align: 'left', orderBy: true, showHint: true,
  },

  {
    id: 'ExpandirAction', label: '', minWidth: 20, align: 'center', isAction: true, isExpansivel: true,

  },
  {
    id: 'action', label: '', minWidth: 20, align: 'center', isAction: true,
  },
];

export const pesquisaProcessoExpandirColumns = [
  { id: 'ultimoRelatorio', label: 'Último relatório:', minWidth: 100, align: 'center' },
];

export function buildpesquisaProcessosExtraRows(details) {
  return details.map((detail, index) => {
    const key = detail?.id || index;

    return (
      <div style={{ display: 'block', width: '100%' }} key={key}>
        <div>
          <TableRoot>
            <TableContainer sx={{ maxHeight: 440, width: '100%' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {pesquisaProcessoExpandirColumns.map(column => (
                      <TableCell
                        key={`${key}-${column.id}`}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        <div style={{ display: 'flex', justifyContent: column.align }}>
                          {column.label}
                        </div>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow hover role="checkbox" tabIndex={-1} key={`${key}-row`}>
                    <TableCell
                      align="center"
                      style={{
                        maxWidth: '100%',
                        overflowWrap: 'break-word',
                        whiteSpace: 'normal',
                      }}
                    >
                      {detail.ultimoRelatorio}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </TableRoot>
        </div>
      </div>
    );
  });
}
