export const MONEY = 'money';
export const STRING = 'string';

export function buildTableParameters(data) {
  if (!data) {
    return {
      content: [],
      pageableOptions: {
        count: 0,
        last: true,
      },
    };
  }
  return {
    content: data.content,
    pageableOptions: {
      count: data.totalElements,
      last: data.last,
    },
  };
}

export function getPage(totalElements = 0, page = 0, isLastPage) {
  if (isLastPage && totalElements === 0 && page !== 0) {
    return page - 1;
  }
  return page;
}

export function sanitazeValue(column, value) {
  if (!value && column?.type !== MONEY) {
    return '-';
  }

  return (column?.format ? column.format(value) : value);
}

export function applyEllipsis(column, value) {
  value = sanitazeValue(column, value);

  if (value.length > 20) {
    return `${value.substring(0, 17)}...`;
  }
  return value;
}
