const SET_STATE_PESQUISA_PENDENCIAS_ACTION = 'SET_STATE_PESQUISA_PENDENCIAS_ACTION';
const SET_PAGEABLE_OPTIONS_VALUE_ACTION = 'SET_PAGEABLE_OPTIONS_VALUE_ACTION';
const DEFAULT_STATES_ACTION = 'DEFAULT_STATES_ACTION';

export const setStatePesquisaPendenciasAction = (name, value) => ({
  type: SET_STATE_PESQUISA_PENDENCIAS_ACTION,
  name,
  value,
});

export const defaultStatesAction = () => ({
  type: DEFAULT_STATES_ACTION,
});

function setStatePesquisaPendenciasHandler(state, action) {
  return {
    ...state,
    [action.name]: action.value,
  };
}
export const setPageableOptionsValueAction = (name, value) => ({
  type: SET_PAGEABLE_OPTIONS_VALUE_ACTION,
  name,
  value,
});

function buildDefaultStates() {
  return {
    content: [],
    rowsPerPage: 10,
    page: 0,
    reloadPageOnSocketError: false,
    idSolicitacaoRespondida: null,
    pageableOptions: {
      count: 0,
      last: true,
    },
  };
}

function setPageableOptionsValueHandler(state, action) {
  return {
    ...state,
    pageableOptions: {
      ...state.pageableOptions,
      [action.name]: action.value,
    },
  };
}

const initialStates = () => buildDefaultStates();

const pesquisaPendenciasReducer = (state = initialStates(), action) => {
  switch (action.type) {
    case SET_STATE_PESQUISA_PENDENCIAS_ACTION:
      return setStatePesquisaPendenciasHandler(state, action);
    case SET_PAGEABLE_OPTIONS_VALUE_ACTION:
      return setPageableOptionsValueHandler(state, action);
    default:
      return state;
  }
};

export default pesquisaPendenciasReducer;
