const SET_IDS_CLIENTE_ACTION = 'SET_IDS_CLIENTE_ACTION';

export const setIdsClienteAction = idsCliente => ({
  type: SET_IDS_CLIENTE_ACTION,
  idsCliente,
});

function setIdsClienteHandler(state, action) {
  return {
    ...state,
    idsCliente: action.idsCliente,
  };
}


function buildDefaultStates() {
  return {
    idsCliente: [],
  };
}

const initialStates = () => buildDefaultStates();

const contaCorrenteReducer = (state = initialStates(), action) => {
  switch (action.type) {
    case SET_IDS_CLIENTE_ACTION:
      return setIdsClienteHandler(state, action);
    default:
      return state;
  }
};

export default contaCorrenteReducer;
