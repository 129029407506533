import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import PageLoading from '../PageLoading/PageLoading';
import Toast from '../Toast/Toast';
import Menu from '../Menu/Menu';
import { Root, SystemWrapperPadding } from './systemWrapper.styles';
import { setToastAction } from '../../../../store/system/system.store';

function SystemWrapper({ children }) {
  const theme = useTheme();
  const { isLoading, toast } = useSelector(state => state.system);
  const dispatch = useDispatch();
  const closeToast = () => dispatch(setToastAction());

  return (
    <Root>
      <Menu />
      <SystemWrapperPadding id="wrapper-padding">
        {children}
      </SystemWrapperPadding>
      <div style={{ flex: 1 }}>
        <PageLoading isLoading={isLoading} />
        <Toast openToast={toast.open} type={toast.type} message={toast.message} onClose={closeToast} />
      </div>
    </Root>
  );
}

export default SystemWrapper;

SystemWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};
