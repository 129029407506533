import { combineReducers, createStore } from 'redux';
import systemReducer from './system/system.store';
import pesquisaProcessoReducer from './pesquisa-processo/pesquisa-processo.store';
import pesquisaPendenciasReducer from './pesquisa-pendencias/pesquisa-pendencias.store';
import contaCorrenteReducer from './conta-corrente/contaCorrente.store';
import demonstrativoReducer from './demonstrativo/demonstrativo.store';
import prestacaoDeContasReducer from './prestacao-contas/prestacao-contas.store';
import previsaoRecebimentoReducer from './previsao-recebimento/previsao-recebimento.store';
import salvarEditarDevedorReducer from './salvar-editar-devedor/salvarEditarDevedor.store';

const rootStore = combineReducers({
  system: systemReducer,
  pesquisaProcesso: pesquisaProcessoReducer,
  pesquisaPendencia: pesquisaPendenciasReducer,
  contaCorrente: contaCorrenteReducer,
  demonstrativo: demonstrativoReducer,
  prestacaoDeContas: prestacaoDeContasReducer,
  previsaoRecebimento: previsaoRecebimentoReducer,
  salvarEditarDevedor: salvarEditarDevedorReducer,
});

const store = createStore(rootStore);

export default store;
