import styled from 'styled-components';
import { WHITE } from '../../../../../colors';


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ContainerTabela = styled.div`
  display: flex;
  flex-direction: column;
  color: #fff;
`;

export const ContentWrapper = styled.div`
  flex: 1;
  margin: 0;

  & h2 {
    margin: 0 0 14px;
    font-size: 22px;
  }

  @media screen and (min-width: 1920px) {
    & h2 {
      font-size: 30px;
      margin-bottom: 30px;
    }
  }
`;

export const BackButtonWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: ${WHITE};
  margin: 40px 0 50px;

  & svg {
    margin-right: 5px;
  }
`;
