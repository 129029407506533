import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import ProtectedRoute from './ProtectedRoute';
import PageLoading from '../components/UI/PageLoading/PageLoading';
import LancamentosPage from '../pages/financeiro/prestacao-contas/lancamentos/LancamentosPage';

const LoginPage = lazy(() => import('../pages/login/LoginPage'));
const NotMatchPage = lazy(() => import('../pages/not-match/NotMatchPage'));
const PesquisaDevedor = lazy(() => import('../pages/pesquisa/devedor/PesquisaDevedor'));
const PesquisaProcesso = lazy(() => import('../pages/pesquisa/processos/PesquisaProcesso'));
const InspecionarProcessorPage = lazy(() => import('../pages/pesquisa/processos/inspecionar/InspecionarProcessoPage'));
const PesquisarPendencias = lazy(() => import('../pages/pesquisa/pendencias/PesquisaPendencias'));
const ResponderPendencia = lazy(() => import('../pages/pesquisa/pendencias/responder/ResponderPendencia'));
const BaixarBordero = lazy(() => import('../pages/bordero/BaixarBordero'));
const EnvioEmail = lazy(() => import('../pages/email/EnvioDeEmail'));
const ProcessarBorderoPage = lazy(() => import('../pages/processar-bordero/ProcessarBordero'));
const DetalhamentoLancamentos = lazy(() => import('../pages/conta-corrente/detalhar-lancamentos/DetalhamentoLancamentos'));
const PrevisaoDeRecebimento = lazy(() => import('../pages/previsao-recebimento/PrevisaoDeRecebimento'));
const ContaCorrente = lazy(() => import('../pages/conta-corrente/ContaCorrentePage'));
const FaleConosco = lazy(() => import('../pages/fale-conosco/FaleConosco'));
const MapaPage = lazy(() => import('../pages/mapa/MapaPage'));
const FaleConoscoEmail = lazy(() => import('../pages/fale-conosco/email/FaleConoscoEmail'));
const PerfilCliente = lazy(() => import('../pages/perfil-cliente/PerfilCliente'));
const RedefinirSenha = lazy(() => import('../pages/redefinir-senha/RedefinirSenha'));
const ForgotPassword = lazy(() => import('../pages/esqueci-minha-senha/ForgotPassword'));
const DemonstrativoPage = lazy(() => import('../pages/demonstrativo/DemonstrativoPage'));
const PrestacaoDeContasPage = lazy(() => import('../pages/financeiro/prestacao-contas/PrestacaoDeContasPage'));
const NotasFiscaisPage = lazy(() => import('../pages/financeiro/prestacao-contas/nota-fiscal/NotasFiscaisPage'));
const Relatorios = lazy(() => import('../pages/relatorio/Relatorios'));
const RelatorioPowerBi = lazy(() => import('../pages/relatorio-power-bi/RelatorioPowerBi'));
const ParcelasNaoLiquidadas = lazy(() => import('../pages/previsao-recebimento/parcelas-nao-liquidadas/ParcelasNaoLiquidadas'));
const EnviarBorderosDevedor = lazy(() => import('../pages/enviar-novo-devedor/EnviarBorderosDevedor'));
const CrudDevedor = lazy(() => import('../pages/enviar-novo-devedor/crudDevedor/CrudDevedor'));
const StatusGeral = lazy(() => import('../pages/status-geral/StatusGeral'));
const SaibaMaisCobranca = lazy(() => import('../pages/saiba-mais-cobranca/SaibaMaisCobranca'));
const SaibaMaisRecebimento = lazy(() => import('../pages/saiba-mais-recebimento/SaibaMaisRecebimento'));
const Dashboard = lazy(() => import('../pages/home/Dashboard'));

export default function Paths() {
  return (
    <BrowserRouter>
      <Suspense fallback={<PageLoading isLoading />}>
        <Routes>
          <Route path="*" element={<NotMatchPage />} />
          <Route path="/" element={<LoginPage />} />
          <Route path="/esqueci-minha-senha" element={<ForgotPassword />} />

          <Route
            path="/relatorios"
            element={(
              <ProtectedRoute>
                <Relatorios />
              </ProtectedRoute>
          )}
          />

          <Route
            path="/fale-conosco"
            element={(
              <ProtectedRoute>
                <FaleConosco />
              </ProtectedRoute>
          )}
          />

          <Route
            path="/perfil"
            element={(
              <ProtectedRoute>
                <PerfilCliente />
              </ProtectedRoute>
          )}
          />
          <Route
            path="/pesquisa-devedor"
            element={(
              <ProtectedRoute>
                <PesquisaDevedor />
              </ProtectedRoute>
          )}
          />

          <Route
            path="/relatorio-power-bi"
            element={(
              <ProtectedRoute>
                <RelatorioPowerBi />
              </ProtectedRoute>
          )}
          />

          <Route
            path="/pesquisa-processo"
            element={(
              <ProtectedRoute>
                <PesquisaProcesso />
              </ProtectedRoute>
          )}
          />
          <Route
            path="/pesquisa-pendencias"
            element={(
              <ProtectedRoute>
                <PesquisarPendencias />
              </ProtectedRoute>
          )}
          />
          <Route
            path="/processo/:idProcesso/inspecionar"
            element={(
              <ProtectedRoute>
                <InspecionarProcessorPage />
              </ProtectedRoute>
          )}
          />
          <Route
            path="/pendencia/:idSolicitacao/responder"
            element={(
              <ProtectedRoute>
                <ResponderPendencia />
              </ProtectedRoute>
          )}
          />
          <Route
            path="/bordero-baixar-modelo"
            element={(
              <ProtectedRoute>
                <BaixarBordero />
              </ProtectedRoute>
          )}
          />
          <Route
            path="/enviar-email"
            element={(
              <ProtectedRoute>
                <EnvioEmail />
              </ProtectedRoute>
          )}
          />
          <Route
            path="/processar-bordero"
            element={(
              <ProtectedRoute>
                <ProcessarBorderoPage />
              </ProtectedRoute>
          )}
          />
          {/* <Route */}
          {/*  path="/saiba-mais" */}
          {/*  element={( */}
          {/*    <ProtectedRoute> */}
          {/*      <SaibaMais /> */}
          {/*    </ProtectedRoute> */}
          {/* )} */}
          {/* /> */}
          <Route
            path="/conta-corrente/detalhar-lancamentos"
            element={(
              <ProtectedRoute>
                <DetalhamentoLancamentos />
              </ProtectedRoute>
          )}
          />
          <Route
            path="/previsao-de-recebimento"
            element={(
              <ProtectedRoute>
                <PrevisaoDeRecebimento />
              </ProtectedRoute>
          )}
          />
          <Route
            path="/parcelas-nao-liquidadas"
            element={(
              <ProtectedRoute>
                <ParcelasNaoLiquidadas />
              </ProtectedRoute>
          )}
          />
          <Route
            path="/conta-corrente"
            element={(
              <ProtectedRoute>
                <ContaCorrente />
              </ProtectedRoute>
          )}
          />
          <Route
            path="/analise-regional"
            element={(
              <ProtectedRoute>
                <MapaPage />
              </ProtectedRoute>
          )}
          />
          <Route
            path="/fale-conosco-email"
            element={(
              <ProtectedRoute>
                <FaleConoscoEmail />
              </ProtectedRoute>
          )}
          />
          <Route
            path="/recovery"
            element={(
              <RedefinirSenha />
          )}
          />

          <Route
            path="/demonstrativo"
            element={(
              <ProtectedRoute>
                <DemonstrativoPage />
              </ProtectedRoute>
              )}
          />

          <Route
            path="/prestacao-contas"
            element={(
              <ProtectedRoute>
                <PrestacaoDeContasPage />
              </ProtectedRoute>
          )}
          />
          <Route
            path="/lancamentos"
            element={(
              <ProtectedRoute>
                <LancamentosPage />
              </ProtectedRoute>
          )}
          />
          <Route
            path="/notas-fiscais"
            element={(
              <ProtectedRoute>
                <NotasFiscaisPage />
              </ProtectedRoute>
          )}
          />
          <Route
            path="/borderos/enviar"
            element={(
              <ProtectedRoute>
                <EnviarBorderosDevedor />
              </ProtectedRoute>
          )}
          />
          <Route
            path="/devedor/novo"
            element={(
              <ProtectedRoute>
                <CrudDevedor isEdit={false} />
              </ProtectedRoute>
          )}
          />
          <Route
            path="/devedor/editar"
            element={(
              <ProtectedRoute>
                <CrudDevedor isEdit />
              </ProtectedRoute>
              )}
          />
          <Route
            path="/dashboard"
            element={(
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
                )}
          />
          <Route
            path="/status-geral"
            element={(
              <ProtectedRoute>
                <StatusGeral />
              </ProtectedRoute>
          )}
          />
          <Route
            path="/saiba-mais-cobranca"
            element={(
              <ProtectedRoute>
                <SaibaMaisCobranca />
              </ProtectedRoute>
          )}
          />
          <Route
            path="/saiba-mais-recebimento"
            element={(
              <ProtectedRoute>
                <SaibaMaisRecebimento />
              </ProtectedRoute>
          )}
          />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}
