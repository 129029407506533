export const WHITE = '#FFF';
export const LIGHT_BLUE = '#8ECAE6';
export const MEDIUM_BLUE = '#69B3D6';
export const DARK_BLUE = '#044DA1';
export const DARK_CYAN = '#005C8B';
export const LIGHT_GREEN = '#A1D96C';
export const LIGHT_GRAY = '#6F6F6F';
export const MEDIUM_GRAY = '#023047';
export const DARK_GRAY = '#323232';
export const COMPANY_LIST = '#252525';
export const LIST_CONTENT = '#303030';
export const DISABLED = '#848484';
export const CALENDAR_BACKGROUND = '#151515';
export const NOTIFCATION_RED = '#C71806';
export const LIGHT_RED = '#F44E4E';
export const CHECKED_RADIO_GREEN = '#1CD14F';
export const DIM_GRAY = '#4d4d4d';
export const GOLD = '#F99600';
export const RED = '#ff0000';
export const DATA_NAO_SELECIONADA = '#4A4A4A';
export const EXCEL_GREEN = '#43A047';
export const WORD_BLUE = '#4D8AFF';
export const VENCIMENTO_FUTURO = '#E0B010';
export const BORDERO_MAP = '#69B3D6';
export const INCOBRAVEL_MAP = '#F44E4E';
export const RECEBIDOS_ABE_MAP = '#A1D96C';
export const ATIVOS_MAP = '#69B3D6';
export const PAGAMENTO_DIRETO_MAP = '#E0B010';
export const VIOLET = '#BA85FF';
export const EXCEL = '#0088CC';
export const MODAL = '#6CB9FF';
export const REGISTER_BUTTON = '#219EBC';
export const PDF = '#005C8B';
export const PDF_SEPARATOR = '#414141';
export const BLUE_GRADIENT = 'linear-gradient(125deg, #1C2F5A 2.34%, #23232F 35.42%)';
export const DASHBOARD_DIVISION = '#555555';
export const DASHBOARD_EXECUTIVO = '#CA994F';
export const GRAFICO_RECEBIMENTO = '#2BE6E2';


export const tipoClienteColors = {
  DIAMANTE: '#B9F2FF',
  OURO: GOLD,
  PRATA: '#C0C0C0',
};
