import styled from 'styled-components';
import Paper from '@mui/material/Paper';
import TableCell from '@mui/material/TableCell';
import { WHITE } from '../../../../colors';


export const TableRoot = styled(Paper)`
    &.MuiPaper-root {
        width: 100%;
        //overflow: hidden;
        background-color: transparent;
        //border: 1px solid #6F6F6F;
    }

    & .MuiTableCell-head {
        background-color: transparent;
        color: ${WHITE};
    }

    & .MuiTableCell-root {
        color: ${WHITE};
        border-bottom: 1px solid #6F6F6F;
        line-height: 1.06rem;
        padding: 10px;
    }

    & .MuiTablePagination-root {
        color: ${WHITE};
        & svg {
            fill: ${WHITE};
        }
    }
    & .MuiTablePagination-displayedRows{
        display: none;
    }

    & .MuiTableContainer-root {
        border: 1px solid #6F6F6F;
    }

    @media screen and (min-width: 1920px) {
        & .MuiTableCell-root {
            line-height: 1.5rem;
            padding: 16px;
        }
    }
`;
export const TableCellEmpty = styled(TableCell)`
   &.MuiTableCell-root {
        max-width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        height: 53px;
        padding: 0;
    }
`;

export const CustomTableCell = styled(TableCell)`
  max-width: 0;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  text-overflow: none;
`;
