import styled from 'styled-components';
import { COMPANY_LIST, DARK_BLUE, LIGHT_GRAY, MEDIUM_BLUE, WHITE } from '../../../../../colors';


export const ResumoModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ResumoModalContent = styled.div`
  background-color: ${COMPANY_LIST};
  color:${WHITE};
  width: 560px;
  height: auto;
  overflow: hidden;
  & hr {
    border: none;
    border-top: 1px dotted ${LIGHT_GRAY};
    background-color: ${COMPANY_LIST};
    height: 1px;
    margin: 10px 0;
  }
`;

export const ResumoModalHeader = styled.div`
  background-color: ${COMPANY_LIST};
  color: ${WHITE};
  padding: 12px 20px 0px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
`;

export const ResumoModalBody = styled.div`
  padding: 26px;
  font-size: 16px;
  line-height: 1.6;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ResumoModalFooter = styled.div`
  padding: 26px;
  text-align: right;
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  color: ${MEDIUM_BLUE};
  font-size: 14px;
  cursor: pointer;
  font-weight: 700;
`;

export const ResumoItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px 0;
`;

export const ResumoTitle = styled.h3`
  font-size: 16px;
  margin: 0;
`;

export const ResumoValue = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  color: ${WHITE};
`;

export const ResumoSaldo = styled.span`
  font-size: 16px;
  color: ${WHITE};
  font-weight:500;
`;
