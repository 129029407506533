import React from 'react';
import {
  FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage,
} from '@mui/icons-material';
import { IconButton, Box } from '@mui/material';

function PaginationActions(props) {
  const {
    count, page, onPageChange,
  } = props;

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.ceil(count / 10) - 1);
  };

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  return (
    <>
      <Box display="flex" justifyContent="center" flexShrink={0} width="100%">
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          title="Primeira Página"
          aria-label="first page"
        >
          <FirstPage />
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
          title="Página Anterior"
          display="flex"
        >
          <KeyboardArrowLeft />
        </IconButton>
        <div>
          <p>{`${page + 1} de ${Math.ceil(count / 10)}`}</p>
        </div>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / 10) - 1}
          aria-label="next page"
          title="Próxima Página"
        >
          <KeyboardArrowRight />
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / 10) - 1}
          title="Última Página"
          aria-label="last page"
        >
          <LastPage />
        </IconButton>
      </Box>
      <Box flexShrink={0}>
        <div style={{ flexShrink: 0, alignSelf: 'end' }}>
          <p>{`Total de resultados: ${count}`}</p>
        </div>
      </Box>
    </>
  );
}

export default PaginationActions;
