import { useMemo } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './useAuth';
import { buildResponseError } from '../factory/http.factory';

// Obtenha a URL da API da variável de ambiente ou use um valor padrão
const API_URL = process.env.REACT_APP_API_SPRING_URL || 'http://localhost:8080';

const useAuthAxios = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  return useMemo(() => {
    const instance = axios.create({
      baseURL: API_URL,
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      withCredentials: true,
      timeout: 50000,
    });

    instance.interceptors.request.use(
      (config) => {
        // Adiciona o token de autenticação se estiver presente
        if (auth.token) {
          config.headers.Authorization = `Bearer ${auth.token}`;
        }

        return config;
      },
      error => Promise.reject(error),
    );

    instance.interceptors.response.use(
      response => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          if (auth.token || window.localStorage.getItem('token')) {
            auth.logout();
          }
          navigate('/', { state: { navigateResponse: 'Sessão Expirada' } });
        }
        error = buildResponseError(error);
        return Promise.reject(error);
      },
    );

    return instance;
  }, [auth.token, navigate]);
};

export default useAuthAxios;
