import dayjs from 'dayjs';

const BUILD_CLIENTE_LOGADO_ACTION = 'BUILD_CLIENTE_LOGADO_ACTION';
const SET_SYSTEM_ACTION = 'SET_SYSTEM_ACTION';
const SET_TOAST_ACTION = 'SET_TOAST_ACTION';
const RESET_SYSTEM_ACTION = 'RESET_SYSTEM_ACTION';

export const currentDay = dayjs();
export const todayPlusMonth = dayjs().add(1, 'month');
export const todayMinusYear = dayjs().subtract(1, 'year');

export const buildClienteLogadoAction = payload => ({
  type: BUILD_CLIENTE_LOGADO_ACTION,
  payload,
});

export const setSystemAction = (name, value) => ({
  type: SET_SYSTEM_ACTION,
  name,
  value,
});

export const setToastAction = (open = false, type = '', message = '') => ({
  type: SET_TOAST_ACTION,
  payload: { open, type, message },
});

export const resetSystemAction = () => ({
  type: RESET_SYSTEM_ACTION,
});

export function buildClienteLogado(payload = null) {
  return {
    nome: payload?.nome || '',
    idCliente: payload?.idCliente || null,
    nomeFantasia: payload?.nomeFantasia || '',
    cnpjCpf: payload?.cnpjCpfCliente || '',
    principal: payload?.principal || false,
    qtdeEmpresas: payload?.qtdeEmpresas || null,
    telefoneWhatsApp: payload?.telefoneWhatsApp || '',
    emailResponsavel: payload?.emailResponsavel || '',
    qtdePendencias: payload?.qtdePendencias || null,
    idResponsavel: payload?.idResponsavel || null,
    nomeResponsavel: payload?.nomeResponsavel || '',
    descricaoClassificacao: payload?.descricaoClassificacao || '',
    ordemClassificacao: payload?.ordemClassificacao || null,
    emailCliente: payload?.emailCliente || '',
    urlRelatorioBI: payload?.urlRelatorioBI || '',
  };
}

const initialStates = () => ({
  clienteLogado: buildClienteLogado(),
  isLoading: false,
  dataInicio: todayMinusYear,
  dataInicioCalendar: null,
  dataFimCalendar: currentDay,
  dataFim: currentDay,
  toast: { open: false, type: '', message: '' },
  currentOption: 'dashboard',
});

function buildClienteLogadoHandler(state, action) {
  return {
    ...state,
    clienteLogado: buildClienteLogado(action.payload),
  };
}

function setSystemHandler(state, action) {
  return {
    ...state,
    [action.name]: action.value,
  };
}

function setToastHandler(state, action) {
  return {
    ...state,
    toast: action.payload,
  };
}

const systemReducer = (state = initialStates(), action) => {
  switch (action.type) {
    case BUILD_CLIENTE_LOGADO_ACTION:
      return buildClienteLogadoHandler(state, action);
    case SET_SYSTEM_ACTION:
      return setSystemHandler(state, action);
    case SET_TOAST_ACTION:
      return setToastHandler(state, action);
    case RESET_SYSTEM_ACTION:
      return initialStates();
    default:
      return state;
  }
};

export default systemReducer;
