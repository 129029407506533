import { formataValorMonetario } from '../../utils/moneyUtils';
import { getEmpresasSelecionadasStorage } from '../../utils/localStorageUtils';
import { demonstrativoColumns } from '../../main/factory/demonstrativo/demonstrativo.factory';
import getSortColumn from '../../utils/utils';

const SET_DEMONSTRATIVO_ACTION = 'SET_DEMONSTRATIVO_ACTION';
const DEFAULT_STATES_ACTION = 'DEFAULT_STATES_ACTION';
const SET_ORDENACAO_ACTION = 'SET_ORDENACAO_ACTION';
const SET_STATE_PESQUISA_DEMONSTRATIVO_ACTION = 'SET_STATE_PESQUISA_DEMONSTRATIVO_ACTION';

export const setOrdenacaoAction = (columnName, direction) => ({
  type: SET_ORDENACAO_ACTION,
  columnName,
  direction,
});

export const setStatePesquisaDemonstrativoAction = (name, value) => ({
  type: SET_STATE_PESQUISA_DEMONSTRATIVO_ACTION,
  name,
  value,
});

export const setDemonstrativoAction = (name, value) => ({
  type: SET_DEMONSTRATIVO_ACTION,
  name,
  value,
});

export const resetDemonstrativoAction = () => ({
  type: DEFAULT_STATES_ACTION,
});

function setOrdenacaoHandler(state, action) {
  const { columnName, direction } = action;
  return {
    ...state,
    ordenacao: [{ columnName, direction }],
    orderBy: getSortColumn(columnName, direction),
  };
}

function setDemonstrativoHandler(state, action) {
  const { name, value } = action;
  return {
    ...state,
    [name]: value,
  };
}

function buildDefaultOrdenacao() {
  return demonstrativoColumns
    .filter(column => column.orderBy)
    .map(column => ({
      columnName: column.id,
      direction: 'DESC',
    }));
}

function setStatePesquisaDemonstrativoHandler(state, action) {
  return {
    ...state,
    [action.name]: action.value,
  };
}

function buildDefaultStates() {
  const empresasSelecionadas = getEmpresasSelecionadasStorage() || [];
  return {
    idsCliente: empresasSelecionadas.map(empresa => empresa.idCliente),
    page: 0,
    content: [],
    pageableOptions: {
      count: 0,
      last: false,
    },
    ordenacao: buildDefaultOrdenacao(),
    totalPagar: formataValorMonetario(0),
    orderBy: getSortColumn('dataDemonstrativo', 'DESC'),
  };
}

function defaultStatesHandler() {
  return buildDefaultStates();
}

const initialStates = () => buildDefaultStates();

const demonstrativoReducer = (state = initialStates(), action) => {
  switch (action.type) {
    case SET_DEMONSTRATIVO_ACTION:
      return setDemonstrativoHandler(state, action);
    case DEFAULT_STATES_ACTION:
      return defaultStatesHandler();
    case SET_ORDENACAO_ACTION:
      return setOrdenacaoHandler(state, action);
    case SET_STATE_PESQUISA_DEMONSTRATIVO_ACTION:
      return setStatePesquisaDemonstrativoHandler(state, action);
    default:
      return state;
  }
};

export default demonstrativoReducer;
