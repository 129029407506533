import dayjs from 'dayjs';

const DAYS = [
  'Domingo',
  'Segunda-Feira',
  'Terça-Feira',
  'Quarta-Feira',
  'Quinta-Feira',
  'Sexta-Feira',
  'Sábado',
];
const MONTHS = [
  'Jan.',
  'Fev.',
  'Mar.',
  'Abr.',
  'Maio',
  'Jun.',
  'Jul.',
  'Ago.',
  'Set.',
  'Out.',
  'Nov.',
  'Dez.',
];

export function formatCPF(value) {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
}

export function formatCNPJ(value) {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d{1,2})$/, '$1-$2');
}

export function formatDocumento(value) {
  return value?.length <= 11 ? formatCPF(value) : formatCNPJ(value);
}

export function formatDate(value) {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})\/(\d{2})(\d)/, '$1/$2/$3')
    .replace(/(\d{2})\/(\d{2})\/(\d{4})(\d)?/, '$1/$2/$3');
}

const WHITE_SPACE = ' ';
export default function getSortColumn(nome, direction) {
  return `${nome}${WHITE_SPACE}${direction}`;
}

const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i += 1) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

function isSafari() {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

export function openPagePDFBase64(data) {
  const relatorio = b64toBlob(data, 'application/pdf');
  const relatorioURL = URL.createObjectURL(relatorio);
  if (isSafari()) {
    window.location = relatorioURL;
  } else {
    window.open(relatorioURL, '_blank');
  }
}

export function downloadFile(response, useCustomName = false) {
  const url = window.URL.createObjectURL(useCustomName ? b64toBlob([response.data?.fileContent ? response.data?.fileContent : response.data?.object]) : b64toBlob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  if (useCustomName === true) {
    link.setAttribute('download', `${response.data.fileName ? response.data.fileName : response.data.name}.xlsx`);
  } else {
    link.setAttribute('download', `${response.headers.filename}.xlsx`);
  }
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(link);
}

function getFormattedDatePart(date, part) {
  return part === 'day'
    ? DAYS[date.getDay()]
    : `${date.getDate()} ${MONTHS[date.getMonth()]}`;
}

export function getWeekdayName(date = new Date()) {
  return getFormattedDatePart(date, 'day');
}

export function getDayAndMonthName(date = new Date()) {
  return getFormattedDatePart(date, 'month');
}

export const arrayDateToFormatatted = (dataArray) => {
  const [ano, mes, dia] = dataArray;
  const date = new Date(ano, mes - 1, dia);
  return date.toLocaleDateString('pt-BR');
};

export const isExcelAvailable = tableData => tableData.length > 0;

export function formatPhoneNumber(value) {
  return value
    .replace(/[() -]/g, '');
}

export function isWithinBusinessHours() {
  const now = new Date();
  const weekDay = now.getDay();
  if (weekDay >= 1 && weekDay <= 5) {
    const currentMinutes = now.getHours() * 60 + now.getMinutes();
    const startMinutes = 8 * 60 + 30;
    const endMinutes = 18 * 60 + 30;
    return currentMinutes >= startMinutes && currentMinutes <= endMinutes;
  }
  return null;
}

export function formatCep(value) {
  if (value) {
    const cleanValue = value.replace(/[^\d]/g, '');

    if (cleanValue.length === 8) {
      return `${cleanValue.slice(0, 5)}-${cleanValue.slice(5)}`;
    }
    return cleanValue;
  }
  return value;
}

export function getCurrentOrderedColumn(_orderBy) {
  const columnName = _orderBy.substring(0, _orderBy.indexOf(' '));
  return columnName;
}

export function downloadPDF(response, fileName = '') {
  const url = window.URL.createObjectURL(b64toBlob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${response?.headers?.filename || fileName}.pdf`);
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(link);
}

export function downloadRelatorioPdf(response, fileName) {
  if (response.status >= 200 && response.status < 300) {
    const file = new Blob([response.data], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);

    const tempLink = document.createElement('a');
    tempLink.href = fileURL;
    tempLink.setAttribute('download', fileName);
    tempLink.click();
  }
}


export const formatObjectToDate = (date) => {
  dayjs(date).format('DD/MM/YYYY');
};

export function getFieldName(targetSelector) {
  if (targetSelector?.nextElementSibling && targetSelector.nextElementSibling.name) {
    return targetSelector?.nextElementSibling.name;
  }
  return targetSelector.name;
}

export function formatTelefoneFixo(value) {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
}

export function formatTelefoneCelular(value) {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
}

export function formatTelefone(value) {
  if (value) {
    const unformatted = value.replace(/\D/g, '');
    return unformatted.length === 10
      ? formatTelefoneFixo(value)
      : formatTelefoneCelular(value);
  }
  return value;
}
