import { formatCep, formatDocumento } from '../../../utils/utils';
import enviarBorderoAnimation from '../../../assets/animations/enviarBorderoAnimation.json';

export const makeDevedorBordero = (data, isEdit = false) => ({
  idSiteDevedor: isEdit ? data.idSiteDevedor : null,
  idCategoria: data?.idCategoria || null,
  cnpjCpf: (formatDocumento(data?.cnpjCpf) || '').trim(),
  razaoSocial: (data?.razaoSocial || '').trim(),
  nomeFantasia: (data?.nomeFantasia || '').trim(),
  nomePreposto: (data?.nomePreposto || '').trim(),
  inscricaoEstadual: (data?.inscricaoEstadual || '').trim(),
  endereco: (data?.endereco || '').trim(),
  bairro: (data?.bairro || '').trim(),
  cep: (formatCep(data?.cep) || '').trim(),
  cidade: (data?.cidade || '').trim(),
  idEstado: (data?.idEstado || '').trim(),
  email: (data?.email || '').trim(),
  telefone1: data?.ddd1 + data?.telefone1.trim(),
  telefone2: data?.ddd2 + data?.telefone2.trim(),
  telefone3: data?.ddd3 + data?.telefone3.trim(),
  telefone4: data?.ddd4 + data?.telefone4.trim(),
  nomeAdvogado: (data?.nomeAdvogado || '').trim(),
  telefoneAdvogado: (data?.telefoneAdvogado || '').trim(),
  codigoNoCliente: (data?.codigoNoCliente || '').trim(),
  instrucoes: (data?.instrucoes || '').trim(),
});

export function makeTituloBordero(data) {
  return data.map(titulo => ({
    idSiteTitulo: titulo.idSiteTitulo,
    idProcesso: titulo.idProcesso,
    idTipoTitulo: titulo.idTipoTitulo,
    numero: titulo.numero,
    valorOriginal: titulo.valorOriginal,
    valorProtesto: titulo.valorProtesto,
    dataVencimento: titulo.dataVencimento,
    possuirProtesto: titulo.possuirProtesto,
  }));
}

export const makeCategoriaPessoa = data => (Array.isArray(data) ? data.map(item => ({
  idCategoria: item?.idCategoria,
  nomeCategoria: item?.nomeCategoria,
  permitirCnpj: item?.permitirCnpj,
})) : []);

export const makeListaEstados = data => (Array.isArray(data) ? data.map(item => ({
  idEstado: item?.idEstado || '',
  nomeEstado: item?.nomeEstado || '',
})) : []);

export function buildDefaultDevedorCommand() {
  return {
    idSiteDevedor: null,
    dataCadastramento: '',
    idCategoria: null,
    cnpjCpf: '',
    razaoSocial: '',
    nomeFantasia: '',
    nomePreposto: '',
    inscricaoEstadual: '',
    endereco: '',
    bairro: '',
    cep: '',
    cidade: '',
    idEstado: '',
    email: '',
    ddd1: '',
    telefone1: '',
    ddd2: '',
    telefone2: '',
    ddd3: '',
    telefone3: '',
    ddd4: '',
    telefone4: '',
    nomeAdvogado: '',
    telefoneAdvogado: '',
    codigoNoCliente: '',
    instrucoes: '',
  };
}

export function buildDefaultTituloBordero() {
  return {
    idSiteTitulo: null,
    idProcesso: null,
    idTipoTitulo: '',
    numero: '',
    valorOriginal: null,
    valorProtesto: null,
    dataVencimento: '',
    possuirProtesto: '',
  };
}

const sanitizeTelefone = (telefone) => {
  if (!telefone) {
    return { ddd: '', telefone: '' };
  }
  const ddd = telefone.substring(0, 2);
  const telefoneNumber = telefone.substring(2);

  return { ddd, telefone: telefoneNumber };
};

export function makeSalvarEditarPayload(
  devedorBorderoCommand = {},
  titulosBorderoCommand = [],
  idCliente = null,
  idProcesso = null,
  idSiteTitulosExclusao = null,
  isEdit = false,
) {
  const telefone1 = sanitizeTelefone(devedorBorderoCommand.telefone1);
  const telefone2 = sanitizeTelefone(devedorBorderoCommand.telefone2);
  const telefone3 = sanitizeTelefone(devedorBorderoCommand.telefone3);
  const telefone4 = sanitizeTelefone(devedorBorderoCommand.telefone4);

  if (isEdit) {
    // Limpa os id's de titulos que foram adicionados durante a edição
    // typeof string para verificar se não é um uuid colocado temporariamente no titulo para poder editar antes de salvar.
    const titulosEditarSanitize = titulosBorderoCommand.map(titulo => ({
      ...titulo,
      idProcesso,
      idSiteTitulo: typeof titulo.idSiteTitulo === 'string' ? null : titulo.idSiteTitulo,
    }));

    return {
      devedorBorderoCommand: {
        ...devedorBorderoCommand,
        ddd1: telefone1.ddd,
        telefone1: telefone1.telefone,
        ddd2: telefone2.ddd,
        telefone2: telefone2.telefone,
        ddd3: telefone3.ddd,
        telefone3: telefone3.telefone,
        ddd4: telefone4.ddd,
        telefone4: telefone4.telefone,
      },
      titulosBorderoCommand: titulosEditarSanitize,
      idCliente,
      idProcesso,
      idSiteTitulosExclusao,
    };
  }
  const titulosIncluirSanitize = titulosBorderoCommand.map((titulo) => {
    titulo.idSiteTitulo = null;
    return titulo;
  });

  return {
    devedorBorderoCommand: {
      ...devedorBorderoCommand,
      ddd1: telefone1.ddd,
      telefone1: telefone1.telefone,
      ddd2: telefone2.ddd,
      telefone2: telefone2.telefone,
      ddd3: telefone3.ddd,
      telefone3: telefone3.telefone,
      ddd4: telefone4.ddd,
      telefone4: telefone4.telefone,
    },
    titulosBorderoCommand: titulosIncluirSanitize,
    idCliente,
    idProcesso: null,
    idSiteTitulosExclusao: null,
  };
}

export function makeEnviarBorderoPayload(rows, idClienteLogado, nomeClienteLogado) {
  const idsSiteDevedor = rows.map(row => row.idSiteDevedor);
  return {
    idsSiteDevedor,
    idClienteLogado,
    nomeClienteLogado,
  };
}

export const animationOptions = {
  loop: true,
  autoplay: true,
  animationData: enviarBorderoAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};
