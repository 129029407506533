import { Stomp } from '@stomp/stompjs';
import SockJS from 'sockjs-client';
import { setToastAction } from '../store/system/system.store';
import { getEmpresasSelecionadasStorage } from '../utils/localStorageUtils';
import { setStatePesquisaPendenciasAction } from '../store/pesquisa-pendencias/pesquisa-pendencias.store';

class WebSocketApi {
  static connect(dispatch) {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    };

    const client = Stomp.over(
      () => new SockJS(
        `${process.env.REACT_APP_API_SPRING_URL || 'http://localhost:8080'}/ws`,
        { transports: ['xhr-streaming', 'xhr-polling'] },
        headers,
      ),
    );

    client.debug = () => {};

    client.reconnect_delay = 5000;

    client.connect(headers, (frame) => {
      client.subscribe('/queue/pendencia', (obj) => {
        const body = JSON.parse(obj.body);
        const empresas = getEmpresasSelecionadasStorage();
        const selecionados = empresas
          .filter(selected => selected.isSelected)
          .map(emp => emp.idCliente);

        if (selecionados.includes(body.idCliente)) {
          if (body.status === 200) {
            dispatch(
              setToastAction(
                true,
                'success',
                `Resposta da Solicitação realizada com sucesso. Processo: ${body.idProcesso}`,
              ),
            );
          } else {
            dispatch(
              setStatePesquisaPendenciasAction('reloadPageOnSocketError', true),
            );
            dispatch(
              setToastAction(
                true,
                'error',
                `Ocorreu um erro ao responder a Solicitação do Processo ${body.idProcesso}. Por favor tente novamente`,
              ),
            );
          }
        }
      });
    });
    return client;
  }

  static disconnect(stompClient) {
    stompClient?.disconnect();
  }
}

export default WebSocketApi;
