import * as React from 'react';
import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { v4 as uuidv4 } from 'uuid';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Tooltip } from '@mui/material';
import { CustomTableCell, TableCellEmpty, TableRoot } from './table.styles';
import { applyEllipsis, sanitazeValue } from '../../../factory/tableFactory';
import PaginationActions from './pagination/paginationAction';
import { LIGHT_BLUE } from '../../../../colors';

export default function Tabela({
  columns = [],
  rows = [],
  page = 0,
  count = 0,
  ordenacao = null,
  handleOrdenacaoChange = () => { },
  changePage = () => { },
  details = null,
  columnDefault = '',
  currentColumn = '',
}) {
  const [orderedColumn, setOrderedColumn] = useState('');
  const [expanded, setExpanded] = useState([]);

  const handleColumnClick = (columnId) => {
    setOrderedColumn(columnId);
    handleOrdenacaoChange(columnId);
  };

  const onClickExpanded = (index) => {
    const currentElemnt = expanded[index];
    currentElemnt.open = !expanded[index].open;
    setExpanded(states => ({
      ...states,
      currentElemnt,
    }));
  };

  /* if there are no saved ordered column on the storage or a no column was chosen, the orderedColumn
  ** will be the default one. Otherwise, the orderedColumn will be the currently used one */
  useEffect(() => {
    if (!currentColumn && !orderedColumn) {
      setOrderedColumn(columnDefault);
    } else if (currentColumn) {
      setOrderedColumn(currentColumn.split(' ')[0]);
    }
  });

  useEffect(() => {
    if (details) {
      const isOpen = rows.map(() => ({
        open: false,
      }));
      setExpanded(isOpen);
    }
  }, [rows, details]);

  return (
    <TableRoot style={{ border: 'none', boxShadow: 'none' }}>
      <TableContainer sx={{ maxHeight: 'none' }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => {
                const currentOrdenacao = ordenacao ? ordenacao.find(item => item.columnName === column.id) : null;
                let icon = null;
                if (column.id === orderedColumn) {
                  icon = currentOrdenacao.direction === 'ASC' ? <ArrowDropUp /> : <ArrowDropDown />;
                }
                return (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, cursor: column.orderBy ? 'pointer' : 'default' }}
                    onClick={column.orderBy ? () => handleColumnClick(column.id) : null}
                  >
                    <div style={{ display: 'flex', justifyContent: column.align, alignItems: 'center' }}>
                      {column.label}
                      {icon}
                    </div>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>

          <TableBody style={{ backgroundColor: 'transparent' }}>
            {Array.from({ length: 10 })
              .map((_, index) => {
                const row = rows[index];
                if (row) {
                  return (
                    <React.Fragment key={uuidv4()}>
                      <TableRow hover role="checkbox" tabIndex={-1} key={uuidv4()}>
                        {columns.map((column) => {
                          const value = row[column.id];
                          if (column.isExpansivel) {
                            return (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                style={{
                                  maxWidth: 0,
                                  overflow: 'hidden',
                                }}
                              >
                                <div style={{ cursor: 'pointer', color: `${LIGHT_BLUE}` }} onClick={() => { onClickExpanded(index); }}>{expanded[index]?.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</div>
                              </TableCell>
                            );
                          }
                          return (
                            <CustomTableCell
                              key={column.id}
                              align={column.align}
                            >
                              <Tooltip title={column.showHint && sanitazeValue(column, value).length >= 20 ? sanitazeValue(column, value) : ''}>
                                { column?.isApplyEllipsis === false ? value : applyEllipsis(column, value)}
                              </Tooltip>
                            </CustomTableCell>
                          );
                        })}
                      </TableRow>
                      <TableRow key={`collapse-${uuidv4()}`}>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={columns.length}>
                          <Collapse in={expanded[index]?.open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                              {details ? details[index] : ''}
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  );
                }
                return (
                  <TableRow key={`empty-row-${uuidv4()}`} tabIndex={-1}>
                    {columns.map(column => (
                      <TableCellEmpty
                        key={`empty-cell-${column.id}`}
                        align={column.align}
                      />
                    ))}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        rowsPerPageOptions={[]}
        rowsPerPage={10}
        count={count}
        page={page}
        onPageChange={changePage}
        ActionsComponent={PaginationActions}
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      />
    </TableRoot>
  );
}
