import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { decodeJwt } from 'jose';
// import { verifyJWT } from '../../utils/JwtUtils';
import { useAuth } from '../hooks/useAuth';

function ProtectedRoute({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const auth = useAuth();
  const navigate = useNavigate();

  const verifyToken = useCallback(async () => {
    const token = localStorage.getItem('token');
    if (token) {
      const verifiedPayload = await decodeJwt(token);
      if (verifiedPayload.idCliente) {
        setIsAuthenticated(true);
      }
    } else {
      auth.logout();
      navigate('/', { state: { navigateResponse: 'Sessão Expirada' } });
    }
    return null;
  }, [navigate]);

  useEffect(() => {
    verifyToken().then();
  }, [verifyToken]);

  if (isAuthenticated) {
    return children;
  }
}

export default ProtectedRoute;
