import React from 'react';
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCashRegister, faFilePen, faWallet } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { CustomDialog, Option } from './financeiroSelectOption.styles';
import { WHITE } from '../../../colors';
import { setSystemAction } from '../../../store/system/system.store';

function FinanceiroSelectOptions({ open, onClose }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleConfirmSelection = (option) => {
    if (option === 'conta-corrente') {
      navigate('/conta-corrente');
    } else if (option === 'demonstrativo') {
      navigate('/demonstrativo');
    } else if (option === 'prestacao-de-conta') {
      navigate('/prestacao-contas');
    }
    dispatch(setSystemAction('currentOption', 'financeiro'));
    onClose();
  };

  return (
    <CustomDialog open={open} onClose={onClose}>
      <DialogTitle>Financeiro</DialogTitle>
      <DialogContentText>
        Selecione a opção desejada:
      </DialogContentText>
      <DialogContent>
        <Option>
          <div id="conta-corrente">
            <div
              onClick={() => handleConfirmSelection('conta-corrente')}
            >
              <FontAwesomeIcon icon={faWallet} />
              Conta Corrente
            </div>
          </div>
        </Option>
        <Option>
          <div id="demonstrativo">
            <div
              onClick={() => handleConfirmSelection('demonstrativo')}
            >
              <FontAwesomeIcon icon={faFilePen} />
              Demonstrativos em Aberto
            </div>
          </div>
        </Option>
        <Option>
          <div id="prestacao-de-conta">
            <div
              onClick={() => handleConfirmSelection('prestacao-de-conta')}
            >
              <FontAwesomeIcon icon={faCashRegister} />
              Prestações de Contas
            </div>
          </div>
        </Option>
      </DialogContent>
      <DialogActions>
        <Button style={{ color: `${WHITE}` }} onClick={onClose}>
          Cancelar
        </Button>
      </DialogActions>
    </CustomDialog>
  );
}

export default FinanceiroSelectOptions;
