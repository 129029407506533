import styled from 'styled-components';

export const Root = styled.div`
  /* background-color: ${({ theme }) => theme.backgroundColor}; */
  background-image: linear-gradient(125deg, #1C2F5A 2.34%, #23232F 35.42%);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const SystemWrapperPadding = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.padding};
  /* overflow: scroll; */
`;
