import { getEmpresasSelecionadasStorage } from '../../utils/localStorageUtils';
import { currentDay, todayMinusYear } from '../system/system.store';

const SET_PRESTACAO_DE_CONTAS_ACTION = 'SET_PRESTACAO_DE_CONTAS_ACTION';
const SET_IDS_CLIENTE_ACTION = 'SET_IDS_CLIENTE_ACTION';
const DEFAULT_STATES_ACTION = 'DEFAULT_STATES_ACTION';

export const setPrestacaoDeContasAction = (name, value) => ({
  type: SET_PRESTACAO_DE_CONTAS_ACTION,
  name,
  value,
});

export const setIdsClienteAction = idsCliente => ({
  type: SET_IDS_CLIENTE_ACTION,
  idsCliente,
});

export const resetPrestacaoDeContasAction = () => ({
  type: DEFAULT_STATES_ACTION,
});

function setPrestacaoDeContasHandler(state, action) {
  const { name, value } = action;
  return {
    ...state,
    [name]: value,
  };
}

function setIdsClienteHandler(state, action) {
  return {
    ...state,
    idsCliente: action.idsCliente,
  };
}

function buildDefaultStates() {
  const empresasSelecionadas = getEmpresasSelecionadasStorage() || [];
  return {
    idsCliente: empresasSelecionadas.map(empresa => empresa.idCliente),
    page: 0,
    content: [],
    pageableOptions: {
      count: 0,
      last: false,
    },
    dataInicio: todayMinusYear,
    dataFim: currentDay,
    isTableVisible: false,
  };
}

function defaultStatesHandler() {
  return buildDefaultStates();
}

const initialStates = () => buildDefaultStates();

const prestacaoDeContasReducer = (state = initialStates(), action) => {
  switch (action.type) {
    case SET_PRESTACAO_DE_CONTAS_ACTION:
      return setPrestacaoDeContasHandler(state, action);
    case SET_IDS_CLIENTE_ACTION:
      return setIdsClienteHandler(state, action);
    case DEFAULT_STATES_ACTION:
      return defaultStatesHandler();
    default:
      return state;
  }
};

export default prestacaoDeContasReducer;
