import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LogoutIcon from '@mui/icons-material/Logout';
import {
  CloseButton,
  HamburgerButton,
  LinkItem,
  ListContainer,
  ListItem,
  LogoContainer,
  LogoutContainer,
  MenuContainer,
  MobileMenu,
} from './menu.styles';
import { resetPesquisaProcessoStatesAction } from '../../../../store/pesquisa-processo/pesquisa-processo.store';
import { menuOptions } from '../../../maps/menu.options';
import logo from '../../../../assets/logo-abe-dourada.svg';
import { useAuth } from '../../../hooks/useAuth';
import FinanceiroSelectOptions from '../../SelectOption/FinanceiroSelectOption';
import { resetPrestacaoDeContasAction } from '../../../../store/prestacao-contas/prestacao-contas.store';
import { resetDemonstrativoAction } from '../../../../store/demonstrativo/demonstrativo.store';
import { resetBorderoCadastroAction } from '../../../../store/salvar-editar-devedor/salvarEditarDevedor.store';
import { resetPrevisaoRecebimentoAction } from '../../../../store/previsao-recebimento/previsao-recebimento.store';
import { resetSystemAction, setSystemAction } from '../../../../store/system/system.store';

export default function Menu() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const authAxios = useAuth();
  const [selected, setSelected] = useState(location.pathname.slice(1));
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const { currentOption } = useSelector(state => state.system);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsMobileMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleLogout = () => {
    dispatch(resetPesquisaProcessoStatesAction());
    dispatch(resetPrestacaoDeContasAction());
    dispatch(resetDemonstrativoAction());
    dispatch(resetBorderoCadastroAction());
    dispatch(resetSystemAction());
    authAxios.logout();
    navigate('/', { replace: true });
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleFinanceiroClickOpen = () => {
    setOpen(true);
  };

  const handleFinanceiroClose = () => {
    setOpen(false);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleFinanceiroClose();
    }
  };

  const handleSelect = (id) => {
    dispatch(resetPesquisaProcessoStatesAction());
    dispatch(resetPrestacaoDeContasAction());
    dispatch(resetDemonstrativoAction());
    dispatch(resetBorderoCadastroAction());
    dispatch(resetPrevisaoRecebimentoAction());
    if (id === 'financeiro') {
      handleFinanceiroClickOpen();
    } else {
      dispatch(setSystemAction('currentOption', id));
      navigate(`/${id}`, { replace: false });
    }
    setSelected(id);
  };

  return (
    <MenuContainer>
      <LogoContainer onClick={() => handleSelect('dashboard')}>
        <img src={logo} alt="logo" />
      </LogoContainer>
      {!isMobileMenuOpen ? (
        <HamburgerButton onClick={toggleMobileMenu}>&#9776;</HamburgerButton>
      ) : (
        <CloseButton onClick={toggleMobileMenu}>&#10005;</CloseButton>
      )}
      <ListContainer>
        {menuOptions.map(option => (
          <ListItem onClick={() => handleSelect(option.id)} key={option.id}>
            <LinkItem $isSelected={currentOption ? currentOption === option.id : option.id === 'dashboard'}>
              {option.icon}
              {option.label}
            </LinkItem>
          </ListItem>
        ))}
      </ListContainer>
      {isMobileMenuOpen && (
        <MobileMenu>
          {menuOptions.map(option => (
            <ListItem key={option.id}>
              <LinkItem
                onClick={() => handleSelect(option.id)}
                isSelected={selected === option.id}
              >
                {option.label}
              </LinkItem>
            </ListItem>
          ))}
        </MobileMenu>
      )}
      <LogoutContainer onClick={handleLogout}>
        <LogoutIcon />
        {' '}
        Sair
      </LogoutContainer>

      <FinanceiroSelectOptions open={open} onClose={handleFinanceiroClose} />
    </MenuContainer>
  );
}
