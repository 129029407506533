import React, {
  createContext,
  useState,
  useContext,
  useMemo, useCallback, useEffect,
} from 'react';

import PropTypes from 'prop-types';
import { decodeJwt } from 'jose';
import { useDispatch } from 'react-redux';
import { buildClienteLogado, buildClienteLogadoAction, setToastAction } from '../../store/system/system.store';
import WebSocketApi from '../../socket/WebSocketApi';

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  const dispatch = useDispatch();

  const [tokenState, setTokenState] = useState(null);
  const [stompClient, setStompClient] = useState(null);

  /**
   * Checks if the current user is authenticated.
   *
   * @async
   * @function isAuthenticated
   * @returns {Promise<boolean>} - A boolean indicating if the user is authenticated.
   */
  const isAuthenticated = useCallback(async () => {
    const token = tokenState || window.localStorage.getItem('token');

    if (token === null) {
      return false;
    }
    const { exp } = await decodeJwt(String(token));
    const expiresAt = exp * 1000;

    // Expired token...
    return Date.now() < expiresAt;
  }, [tokenState]);

  const connectWebSocket = useCallback(async () => {
    const client = await WebSocketApi.connect(dispatch);
    setStompClient(client);
  }, [dispatch]);

  async function disconnectWebSocket() {
    if (stompClient) {
      await WebSocketApi.disconnect(stompClient);
      setStompClient(null);
    }
  }

  /**
   * This useEffect hook is being used to authenticate the user
   * and then establish a WebSocket connection if not already connected.
   *
   * The useEffect hook runs whenever the 'stompClient', 'isAuthenticated',
   * or 'connectWebSocket' dependencies change.
   *
   * Firstly, it checks if the user is authenticated by calling the 'isAuthenticated' function.
   * If the user is authenticated and 'stompClient' is null (indicating no current WebSocket connection),
   * it makes a call to the 'connectWebSocket' function to establish the WebSocket connection.
   */
  useEffect(() => {
    const checkAuthAndConnect = async () => {
      const auth = await isAuthenticated();

      if (auth && stompClient === null) {
        await connectWebSocket();
      }
    };

    checkAuthAndConnect().then();
  }, [stompClient, isAuthenticated, connectWebSocket]);

  const login = (loginResponse) => {
    const { token } = loginResponse.data;
    setTokenState(token);
    window.localStorage.setItem('token', token);
    connectWebSocket().then();
  };

  const decodeJwtToStore = useCallback(async (token) => {
    const payload = await decodeJwt(token);
    dispatch(buildClienteLogadoAction(payload));
    localStorage.setItem('clienteLogado', JSON.stringify(buildClienteLogado(payload)));
  }, [dispatch]);

  const logout = async () => {
    setTokenState(null);
    window.localStorage.clear();
    if (stompClient) {
      try {
        await disconnectWebSocket();
      } catch (error) {
        dispatch(setToastAction(true, 'error', error.message));
      }
    }
  };

  /**
   * This hook is triggered whenever 'tokenState' changes.
   *
   * First, it checks for a local storage item with the key 'token'.
   * If 'tokenState' is falsy (null, undefined, etc.), and a 'token' value exists in local storage,
   * the 'tokenState' is updated with the 'token' value from local storage.
   * This is essentially loading the token into state from local storage on initialization.
   */
  useEffect(() => {
    const tokenStore = window.localStorage.getItem('token');
    if (!tokenState && tokenStore) {
      setTokenState(tokenStore);
    }
  }, [tokenState]);

  /**
   * This hook runs whenever 'tokenState' or 'decodeJwtToStore' changes.
   *
   * If 'tokenState' is not null, it makes an asynchronous call to the 'decodeJwtToStore' function.
   * This function, as the name suggests, likely decodes the JWT (JSON Web Token) and stores the decoded values.
   */
  useEffect(() => {
    if (tokenState !== null) {
      decodeJwtToStore(tokenState).then();
    }
  }, [tokenState, decodeJwtToStore]);

  const value = useMemo(
    () => ({
      login,
      logout,
      isAuthenticated,
      decodeJwtToStore,
      token: tokenState,
    }),
    [login, logout, isAuthenticated, decodeJwtToStore, tokenState],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth deve estar dentro do contexto de autenticação.');
  }
  return context;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export { AuthProvider, useAuth };
